import request from "../helpers/request";

// obtiene la lista de las reglas de precio
export async function ListRulesPricing(realEstateDevelopmentId) {
  try {
    let data = {};
    if (realEstateDevelopmentId) {
      data.real_estate_developments_id = realEstateDevelopmentId;
    }

    return new Promise((resolve, reject) => {
      request("/pricingRule/list", data)
        .then(response => {
          resolve({
            PricingRules: response.data.PricingRules
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

// registra una nueva regla de precio
export function CreateRulesPricing(rulePricing, realEstateDevelopmentId) {
  try {
    let data = {};
    data = {
      process_type: rulePricing.process_type,
      description: rulePricing.description,
      method_payment: rulePricing.method_payment,
      description_method_payment: rulePricing.description_method_payment,
      financing: rulePricing.financing,
      financing_interest: rulePricing.financing_interest,
      percentage_down_payment: rulePricing.percentage_down_payment,
      description_percentage_down_payment:
        rulePricing.description_percentage_down_payment,
      guarantee_deposit: rulePricing.guarantee_deposit,
      description_guarantee_deposit: rulePricing.description_guarantee_deposit,
      months_agreed: rulePricing.months_agreed,
      montly_payment: rulePricing.montly_payment,
      termination_payment: rulePricing.termination_payment,
      description_termination_payment:
        rulePricing.description_termination_payment,
      apply_discount: rulePricing.apply_discount,
      number_apply_discount: rulePricing.number_apply_discount,
      discounts: rulePricing.discounts,
      propertys_code: rulePricing.propertys_code,
      real_estate_developments_id: realEstateDevelopmentId
    };
    return new Promise((resolve, reject) => {
      request("/pricingRule/create", data)
        .then(response => {
          resolve({
            PricingRules: response.data.PricingRules
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

// actualiza información de una regla de precio
export function UpdatePricingRule(pricingRule, pricingRuleId) {
  try {
    if (!pricingRuleId) return Promise.reject("No se especificó propertyId");

    let data = {
      real_estate_pricing_ruleId: pricingRuleId
    };

    if (pricingRule.process_type) {
      data.process_type = pricingRule.process_type;
    }
    if (pricingRule.description) {
      data.description = pricingRule.description;
    }

    if (pricingRule.method_payment) {
      data.method_payment = pricingRule.method_payment;
    }

    if (pricingRule.description_method_payment) {
      data.description_method_payment = pricingRule.description_method_payment;
    }

    if (pricingRule.financing) {
      data.financing = pricingRule.financing;
    }

    if (pricingRule.financing_interest) {
      data.financing_interest = pricingRule.financing_interest;
    }

    if (pricingRule.percentage_down_payment) {
      data.percentage_down_payment = pricingRule.percentage_down_payment;
    }

    if (pricingRule.description_percentage_down_payment) {
      data.description_percentage_down_payment =
        pricingRule.description_percentage_down_payment;
    }

    if (pricingRule.guarantee_deposit) {
      data.guarantee_deposit = pricingRule.guarantee_deposit;
    }

    if (pricingRule.description_guarantee_deposit) {
      data.description_guarantee_deposit =
        pricingRule.description_guarantee_deposit;
    }

    if (pricingRule.months_agreed) {
      data.months_agreed = pricingRule.months_agreed;
    }

    if (pricingRule.montly_payment) {
      data.montly_payment = pricingRule.montly_payment;
    }

    if (pricingRule.termination_payment) {
      data.termination_payment = pricingRule.termination_payment;
    }

    if (pricingRule.description_termination_payment) {
      data.description_termination_payment =
        pricingRule.description_termination_payment;
    }

    if (pricingRule.apply_discount) {
      data.apply_discount = pricingRule.apply_discount;
    }

    if (pricingRule.number_apply_discount) {
      data.number_apply_discount = pricingRule.number_apply_discount;
    }

    if (pricingRule.termination_payment) {
      data.termination_payment = pricingRule.termination_payment;
    }

    if (pricingRule.description_termination_payment) {
      data.description_termination_payment =
        pricingRule.description_termination_payment;
    }

    if (pricingRule.discounts && pricingRule.discounts.length > 0) {
      data.discounts = pricingRule.discounts;
    }

    if (pricingRule.propertys_code && pricingRule.propertys_code.length > 0) {
      data.propertys_code = pricingRule.propertys_code;
    }
    if (pricingRule.status) {
      data.status = pricingRule.status;
    }
    return new Promise((resolve, reject) => {
      request("/pricingRule/update", data)
        .then(response => {
          resolve({
            Message: response.data.Message,
            PricingRules: response.data.PricingRules
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function ListDiscount(realEstateDevelopmentId) {
  try {
    let data = {};
    if (realEstateDevelopmentId) {
      data.real_estate_developments_id = realEstateDevelopmentId;
    }

    return new Promise((resolve, reject) => {
      request("/discount/list", data)
        .then(response => {
          resolve({
            Discount: response.data.Discount
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

export function CreateDiscount(addDiscount, realEstateDevelopmentId) {
  try {
    let data = {};
    data = {
      method_discount: addDiscount.method_discount,
      discount: addDiscount.discount,
      months_discount: addDiscount.months_discount,
      description_method_discount: addDiscount.description_method_discount,
      properties_apply_discount: addDiscount.properties_apply_discount,
      condition: addDiscount.condition,
      propertys_code: addDiscount.propertys_code,
      real_estate_developments_id: realEstateDevelopmentId
    };
    return new Promise((resolve, reject) => {
      request("/discount/create", data)
        .then(response => {
          resolve({
            Discount: response.data.Discount
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

export function UpdateDiscount(upDiscount, discountId) {
  try {
    if (!discountId) return Promise.reject("No se especificó propertyId");

    let data = {
      real_estate_discountId: discountId
    };

    if (upDiscount.method_discount) {
      data.method_discount = upDiscount.method_discount;
    }

    if (upDiscount.discount) {
      data.discount = upDiscount.discount;
    }

    if (upDiscount.months_discount) {
      data.months_discount = upDiscount.months_discount;
    }

    if (upDiscount.description_method_discount) {
      data.description_method_discount = upDiscount.description_method_discount;
    }

    if (upDiscount.properties_apply_discount) {
      data.properties_apply_discount = upDiscount.properties_apply_discount;
    }

    if (upDiscount.condition) {
      data.condition = upDiscount.condition;
    }

    if (upDiscount.propertys_code && upDiscount.propertys_code.length > 0) {
      data.propertys_code = upDiscount.propertys_code;
    }

    return new Promise((resolve, reject) => {
      request("/discount/update", data)
        .then(response => {
          resolve({
            Message: response.data.Message,
            Discount: response.data.Discount
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
