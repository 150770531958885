<template>
  <div>
    <!--  <v-btn @click="refresh()" class="my-5"
      ><v-icon left> mdi-cached </v-icon>Actualizar</v-btn
    > -->
    <v-row v-if="loading" align="center" justify="center">
      <v-col align-self="center" cols="12">
        <div class="text-center">
          <v-progress-circular
            :width="6"
            :size="150"
            color="primary"
            indeterminate
            style="margin:90px; left: 0; z-index: 2; border: none;"
            >Cargando...</v-progress-circular
          >
        </div>
      </v-col>
    </v-row>
    <div v-else></div>
    <!-- <div id="previewPDF"></div> -->
    <div v-show="!loading">
      <iframe
        id="main-iframe"
        style="width: 100%; height: 75vh; position: static; z-index: 2; border: none;"
      ></iframe>
    </div>
  </div>
</template>

<script>
import printPDFQuote from "@/pdf/qoute-print-pdf.js";

export default {
  props: {
    hideCurrency: {
      String: String,
      default: false
    },
    defaultActive: {
      Boolean: Boolean,
      default: false
    },
    logoDesing: {
      String: String,
      default: ""
    },
    imgEstateDesing: {
      String: String,
      default: ""
    },
    realEstateGroupLogoDesing: {
      String: String,
      default: ""
    },
    //new logo footer
    logoFooter: {
      String: String,
      default: ""
    },
    bgOne: {
      String: String,
      default: "#fff"
    },
    bgTwo: {
      String: String,
      default: "#000"
    },
    textColor: {
      String: String,
      default: "#000"
    },
    titleColor: {
      String: String,
      default: "#000"
    },
    titleTwoColor: {
      String: String,
      default: "#000"
    },
    textThreeColor: {
      String: String,
      default: "#000"
    },
    imgEstate: {
      String: String,
      default: ""
    },
    propertyData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      default: false,
      srcLogo: this.logoEstae,
      tab: null,
      currentColor: this.bgOne,
      loading: true
      /*  inLogoDesing: this.imgValidate(this.logoDesing),
      inImgEstateDesing: this.imgValidate(this.imgEstateDesing),
      inRealEstateGroupLogoDesing: this.imgValidate(this.this.realEstateGroupLogoDesing) */
    };
  },
  watch: {
    hideCurrency: function() {
      this.update();
    },
    logoDesing: function() {
      this.update();
    },
    imgEstateDesing: function() {
      this.update();
    },
    realEstateGroupLogoDesing: function() {
      this.update();
    },
    logoFooter: function() {
      this.update();
    },
    bgOne: function() {
      this.update();
    },
    bgTwo: function() {
      this.update();
    },
    textColor: function() {
      if (!this.defaultActive) {
        this.loading = true;
        setTimeout(() => {
          this.refresh();
        }, 500);
        setTimeout(() => {
          this.refresh();
          this.loading = false;
        }, 1200);
      }
    },
    titleColor: function() {
      this.update();
    },
    titleTwoColor: function() {
      this.update();
    },
    textThreeColor: function() {
      this.update();
    },
    defaultActive: function() {
      if (!this.defaultActive) {
        this.refresh();
      }
      this.loading = !this.loading;
    }
  },
  computed: {
    logoPreview() {
      return this.srcLogo;
    },
    logoDesingInternal() {
      return this.imgValidate(this.logoDesing);
    },
    imgEstateDesingInternal() {
      return this.imgValidate(this.imgEstateDesing);
    },
    realEstateGroupLogoDesingInternal() {
      return this.imgValidate(this.realEstateGroupLogoDesing);
    },
    logoFooterInternal() {
      return this.imgValidate(this.logoFooter);
    }
  },

  /* eslint-disable */
  mounted() {
    setTimeout(() => {
      PDFObject.embed(this.createPDF(), "#previewPDF");
      this.loading = false;
    }, 1200);
  },
  methods: {
    update() {
      if (!this.defaultActive) {
        this.loading = true;
        setTimeout(() => {
          this.refresh();
        }, 500);
        setTimeout(() => {
          this.loading = false;
        }, 1200);
      }
    },
    imgValidate(data) {
      if (data !== null) {
        if (typeof data !== "string") {
          return URL.createObjectURL(data);
        } else {
          return data;
        }
      } else {
        return data;
      }
    },
    refresh() {
      if (!this.defaultActive) {
        this.createPDF();
      }
    },
    createPDF() {
      let contactDataDevelopment = {
        email: "info@capital28.investments",
        phone: "+52 998 567 8903"
      };
      let addressDataDevelopment = {
        state: "Q.R",
        city: "Playa del Carmen",
        full_address: "Av. Lorem Ipsum 123 lote 10, 77710"
      };

      let diseño = {
        container_one: this.bgOne,
        container_two: this.bgTwo,
        container_separator: "#636565",
        page_background: "#283042",
        text_color: this.textColor,
        title_color: this.titleColor,
        title2_color: this.titleTwoColor,
        text3_color: this.textThreeColor
      };
      let medias = {
        logo: { src: this.logoDesingInternal },
        featured_image: { src: this.imgEstateDesingInternal },
        logo_footer: { src: this.logoFooterInternal }
        //realEstateGroupLogo:{ src: this.realEstateGroupLogoDesing},
      };

      //Primera parte del pdf
      printPDFQuote.portada(
        "Nombre del Lead",
        "Nombre del Asesor",
        "Español",
        false,
        contactDataDevelopment,
        addressDataDevelopment,
        medias,
        diseño
      );
      ///Segunda Parte del pdf
      let listaPropiedadesSeleccionadas = [
        {
          name: "L-000",
          code: "L-000",
          building: {
            type: "Local",
            construction: 10.5,
            terrace: 10.5,
            total: 0
          },
          media: {
            featured_image: {
              name: "",
              title: "",
              src:
                "https://res.cloudinary.com/invertikal-developers/image/upload/v1622041308/Corner%20Center/Planos/Planta%20Baja/L-110-Model_wvxlhp.jpg",
              cloudinary_id: ""
            }
          },
          pricing: { price: 1000, currency: "MXN" },
          floor: { code: "planta-baja", name: "Planta Baja" },
          type: "estudio",
          features: { rooms: "9", bathrooms: "1" }
          /*    amenities: [
            {
              _id: "62571ccc169cfc6c80fed3ac",
              media: {
                featured_image: {
                  name: "bodega4.png",
                  alt_text: "bodega4",
                  src: "https://res.cloudinary.com/yaajma2/image/upload/v1649364562/planos/nivel%204/4to-Nivel_PH1_3-roofto_p_qsb1g7.jpg"
                },
                icono: {
                  name: "icono-bodega4.png",
                  alt_text: "icono-bodega4",
                  src: "https:///cloudinary/uploads...general/icono-b4.png"
                }
              },
              construction: {
                unit: "m2",
                total: 12
              },
              miscellaneous: {
                mapping_coords: [1611, 574, 1228, 574],
                mapping_coords_building: [1231, 710, 1607, 710, 1624, 670]
              },
              gallery: [
                {
                  name: "image-bodega4.png",
                  alt_text: "image-bodega4",
                  src: "https:///cloudinary/uploads...general/image-b4.png",
                  type: "slider"
                },
                {
                  name: "image2-bodega4.png",
                  alt_text: "image2-bodega4",
                  src: "https:///cloudinary/uploads...general/image2-b4.png",
                  type: "slider"
                }
              ],
              status: 1,
              name: "Rooftop",
              code: "B4",
              abbreviation: "S2-B4",
              alias: "Bodega 4",
              floor: {
                code: "S2",
                name: "Sotano 2"
              },
              real_estate_group_id: "5e9a2df024535649bdc51f54",
              created_at: "2022-04-13T18:56:12.736Z",
              updated_at: "2022-04-13T18:56:12.736Z",
              __v: 0
            }
          ] */
        }
      ];

      let dts = [
        ["Enganche", "50%", "$450"],
        ["Financiamiento", "50%", "$450"],
        ["2 Pagos mensuales", "", "$225"],
        ["Total (Con 10% de descuento)", "100 %", "$900"]
      ];

      const doc = printPDFQuote.departamentos(
        listaPropiedadesSeleccionadas,
        "Nombre del Asesor",
        "Español",
        dts,
        "MXN",
        20,
        false,
        "off",
        0,
        this.realEstateGroupLogoDesingInternal,
        this.hideCurrency
      );
      document
        .getElementById("main-iframe")
        .setAttribute("src", doc.output("bloburl"));
    }
  }
};
</script>
<style>
.pdfobject-container {
  height: 30rem;
  border: 1rem solid rgba(0, 0, 0, 0.1);
}
</style>
