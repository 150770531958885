import { word } from "./multi-language";
let featured_image;
let logo;
let logo_footer;
//Validar el logo....intentar validar datos...
//Funcion para saber si tiene que tener el logo o no...
///Crear funciones para poder validar los logos...

let container_one;
let container_two;

let title_color;
let title2_color;
let text3_color;
let text_color;

let contactMedia =
  "https://res.cloudinary.com/capital28-investments/image/upload/v1594414146/Capital%2028/brokers/testbroker.jpg";

let language = "spanish";
//Style
import LatoBoldnormal from "@/pdf/fonts/LatoBoldnormal";
import LatoRegularnormal from "@/pdf/fonts/LatoRegularnormal";
import LatoLightnormal from "@/pdf/fonts/LatoLightnormal";

LatoRegularnormal();
LatoBoldnormal();
LatoLightnormal();

export function configStyle(style, lang) {
  styleDefault();
  if (!style) return 0;
  defineStyle(style);
  if (lang) language = lang;
}
export function colors() {
  return {
    blue: "#1C2833",
    container_one: container_one,
    container_two: container_two,
    title_color: title_color,
    title2_color: title2_color,
    text3_color: text3_color,
    text_color: text_color,
  };
}

export function background(doc, color) {
  doc.setDrawColor(0);
  if (color) {
    doc.setFillColor(color);
  } else {
    doc.setFillColor(container_one);
  }

  doc.rect(0, 0, 677.3, 381, "F");
}
export function backgroundTwo(doc, line) {
  doc.setDrawColor(0);
  doc.setFillColor(container_two);
  doc.rect(0, 0, 677.3, 190.5, "F");

  doc.setDrawColor(0);
  doc.setFillColor(container_one);
  doc.rect(0, 190.5, 677.3, 190.5, "F");

  if (!line) return 0;
  //Title color..
  doc.setDrawColor(title_color);
  doc.setLineWidth(0.7);
  doc.line(20 * 6.773, 43, 250, 43);
}
export function title(doc, text, x, y, c) {
  doc.setFontSize(30);
  //doc.setTextColor("#1C2833");
  const color = c ? c : title2_color;
  textFont(doc, 35, text, x * 6.773, y * 3.81, "bold", color, false);
  var widthTemp = doc.getTextWidth(text);
  doc.setDrawColor(color);
  doc.setLineWidth(1.5);
  doc.line((x + 1) * 6.773 + widthTemp, (y - 1) * 3.81, 630, (y - 1) * 3.81);
  doc.setFont("Lato-Regular", "normal");
}

export function frontPage(doc, dataPage) {
  backgroundTwo(doc, true);
  imgFrontLogo(doc);
  imgFrontImgFeature(doc);
  applyDataPage(doc, dataPage);
  doc.setProperties({
    title: "Automatic quote",
    subject: "Quote",
    author: "Ing. Cesar A. Moheno (augustmoheno@gmail.com)",
    keywords: "generated, javascript, jspdf, automatic, quote",
    creator: "Created with Capital28",
  });
}

function imgFrontLogo(doc) {
  let [imgWidthHead, imgHeightHead] = ([
    imgWidthHead,
    imgHeightHead,
  ] = scaleImageSize(doc, logo, 90));
  let scalaSize = 90;
  while (imgHeightHead > 40) {
    [imgWidthHead, imgHeightHead] = scaleImageSize(doc, logo, scalaSize);
    scalaSize = scalaSize - 5;
  }

  let scale = imgWidthHead / imgHeightHead;
  if (imgHeightHead >= imgWidthHead) {
    doc.addImage(decodeURIComponent(logo), "PNG", 53, 15, 60 * scale, 60);
  } else {
    if (imgWidthHead + 35 > 120) {
      scale = imgHeightHead / imgWidthHead;

      doc.addImage(decodeURIComponent(logo), "PNG", 20, 30, 100, 100 * scale);
    } else {
      doc.addImage(decodeURIComponent(logo), "PNG", 20, 15, 60 * scale, 60);
    }
  }
}
function imgFrontImgFeature(doc) {
  const { pageHeight, centerWidth } = positionSacale(doc);
  let [imgFeatureWidt, imgFeatureHeight] = scaleImageSize(
    doc,
    featured_image,
    400,
    400,
    true
  );
  if (imgFeatureHeight > 240) {
    const scala = imgFeatureWidt / imgFeatureHeight;
    imgFeatureHeight = 240;
    imgFeatureWidt = 240 * scala;
  }
  const centerHeightFeature = 20 + (pageHeight - imgFeatureHeight) / 2;
  const centerWidthFeature = 60 + (centerWidth - imgFeatureWidt) / 2;
  doc.addImage(
    decodeURIComponent(featured_image),
    "JPG",
    centerWidthFeature,
    centerHeightFeature,
    imgFeatureWidt,
    imgFeatureHeight
  );
}

//Funciones de soporte...
function scaleImageSize(doc, image, width, height, scale) {
  const imgProps = doc.getImageProperties(image);
  const { centerHeight, margin } = positionSacale(doc);
  if (scale === true) {
    if (imgProps.height > centerHeight && imgProps.width < imgProps.height) {
      const imageWidth = (imgProps.width * height) / imgProps.height;
      return [imageWidth - margin, height - margin];
    } else {
      const imageHeight = (imgProps.height * width) / imgProps.width;
      return [width - margin, imageHeight - margin];
    }
  }
  if (width) {
    const imageHeight = (imgProps.height * width) / imgProps.width;
    return [width, imageHeight];
  }
  if (height) {
    const imageWidth = (imgProps.width * height) / imgProps.height;
    return [imageWidth, height];
  }
}

function positionSacale(doc) {
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  return {
    pageWidth,
    pageHeight,
    perimetro: (pageWidth + pageHeight) * 2,
    area: pageWidth * pageHeight,
    margin: 10,
    centerWidth: pageWidth / 2,
    centerHeight: pageHeight / 2,
  };
}
function styleDefault() {
  container_one = "#212121";
  container_two = "#FFFFFF";

  title_color = "#262D3C";
  title2_color = "#FFFFFF";
  text3_color = "#262D3C";
  text_color = "#FFFFFF";
  contactMedia =
    "https://res.cloudinary.com/capital28-investments/image/upload/v1594414146/Capital%2028/brokers/testbroker.jpg";
  logo =
    "https://res.cloudinary.com/capital28-investments/image/upload/v1564761056/Capital%2028/logo-capital-28.png";

  featured_image =
    "https://res.cloudinary.com/capital28-investments/image/upload/v1628213119/Capital%2028/Inmobiliarias/imagem-not-found.jpg";

  logo_footer =
    "https://res.cloudinary.com/capital28-investments/image/upload/v1564761056/Capital%2028/logo-capital-28.png";
}
function defineStyle(style) {
  //Logo...
  if (style.logo) logo = style.logo;
  //Logo Footer
  if (style.logo_footer) logo_footer = style.logo_footer;
  //Imagen Principal de la portada...
  if (style.featured_image) featured_image = style.featured_image;
  //Color del contenedor 1
  if (style.container_one) container_one = style.container_one;
  //Color del contenedor 2
  if (style.container_two) container_two = style.container_two;
  //Titulo del color
  if (style.title_color) title_color = style.title_color;
  //Titulo del color 2
  if (style.title2_color) title2_color = style.title2_color;
  //Titulo del color 3
  if (style.text3_color) text3_color = style.text3_color;
  //Color del texto..
  if (style.text_color) text_color = style.text_color;
}
function applyDataPage(doc, data) {
  doc.setTextColor(container_one);
  textFont(doc, 35, word(1, language), 20, 10, "bold", title_color, true);
  textFont(doc, 35, data.nameLead, 20, 15, "", title_color, true);
}

export function agent(doc, name, x, y, c) {
  !c ? doc.setTextColor(40, 46, 66) : doc.setTextColor(c);
  doc.addImage(decodeURIComponent(contactMedia), "PNG", x, y, 60, 0);
  doc.setFontSize(30);
  doc.setFontSize(30);
  if (name.length < 22) {
    doc.text(name, x + 70, y + 23);
  } else {
    const arr = name.split(" ");

    let count = -1;
    let firstLine = "";
    let secondLine = "";
    const limit = arr.length > 3 ? 17 : 22;
    for (let i = 0; i < arr.length; i++) {
      count = count + arr[i].length + 1;
      if (count > limit) {
        secondLine = secondLine + arr[i] + " ";
      } else {
        firstLine = firstLine + arr[i] + " ";
      }
    }
    doc.text(firstLine, x + 70, y + 23);
    doc.text(secondLine, x + 70, y + 36);

    y += 14;
  }
  doc.setFontSize(30);
  doc.text(word(2, language), x + 70, y + 36);
}

export function dataAgent(doc, data, dataFrontPage) {
  const phone = data.phone ? data.phone : "-";
  const email = data.email ? data.email : "-";

  doc.setTextColor(text_color);
  let cell = `${phone}`;
  cell = cell.replace(/\s+/g, "");
  cell = cell.replace("+52", "");
  if (cell.length === 10) {
    cell =
      "+52 " +
      cell[0] +
      cell[1] +
      cell[2] +
      " " +
      cell[3] +
      cell[4] +
      cell[5] +
      " " +
      cell[6] +
      cell[7] +
      cell[8] +
      cell[9];
  }

  textFont(
    doc,
    25,
    `${word(3, language)}: ${cell}`,
    70,
    54,
    "",
    text_color,
    true
  );

  textFont(doc, 25, `${email}`, 70, 58, "", text_color, true);
  const developmentName = dataFrontPage?.nameDev?.toLowerCase() ?? "";

  if (["zelva 44", "ikonic tower"].includes(developmentName)) {
    const dateXPosition = developmentName === "zelva 44" ? 70 : 10;
    const dateYPosition = developmentName === "zelva 44" ? 88 : 95;
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const generationDate = new Date();
    const day = String(generationDate.getDate()).padStart(2, "0");
    const year = generationDate.getFullYear();
    let formattedDate = ``;
    if (language === "spanish") {
      const month = meses[generationDate.getMonth()];
      formattedDate = `${day}-${month}-${year}`;
    } else {
      const month = months[generationDate.getMonth()];
      formattedDate = `${month}-${day}-${year}`;
    }
    let formattedStringDate = `${formattedDate}`;
    if (["ikonic tower"].includes(developmentName)) {
      formattedStringDate = `${word(92, language)} ${formattedDate}`;
    }
    textFont(
      doc,
      20,
      formattedStringDate,
      dateXPosition,
      dateYPosition,
      "",
      text_color,
      true
    );
  }
}

export function leftData(doc, agent, dataFront) {
  //Datos del broker..
  agentInfo(doc, agent, 470, 110);
  dataAgent(doc, agent, dataFront);
  formattedAddress(doc, `${dataFront.address}`, 36);
}

function agentInfo(doc, agent, x, y, c) {
  //Validar imagen... y el nombre...
  const name = agent.name ? agent.name : `-`;
  if (agent.media) {
    if (agent.media.featured_image) {
      if (agent.media.featured_image.src) {
        contactMedia = agent.media.featured_image.src;
      }
    }
  }

  !c ? doc.setTextColor(40, 46, 66) : doc.setTextColor(c);

  if (contactMedia.includes("cloudinary")) {
    let tempColor = container_two.slice(1);
    let imgSplit = contactMedia.split("upload");
    let newImage =
      imgSplit[0] +
      `upload/w_1000,c_fill,ar_1:1,g_auto,r_max,b_rgb:${tempColor.toLocaleLowerCase()}` +
      imgSplit[1].toString();
    doc.addImage(encodeURI(newImage), "PNG", x, y, 60, 0);
  } else {
    doc.addImage(decodeURIComponent(contactMedia), "PNG", x, y, 60, 0);
  }

  if (name.length < 22) {
    textFont(doc, 30, name, x + 70, y + 23, "bold", text3_color);
  } else {
    const arr = name.split(" ");

    let count = -1;
    let firstLine = "";
    let secondLine = "";
    const limit = arr.length > 3 ? 17 : 22;
    for (let i = 0; i < arr.length; i++) {
      count = count + arr[i].length + 1;
      if (count > limit) {
        secondLine = secondLine + arr[i] + " ";
      } else {
        firstLine = firstLine + arr[i] + " ";
      }
    }
    textFont(doc, 30, firstLine, x + 70, y + 23, "", text3_color);
    textFont(doc, 30, secondLine, x + 70, y + 36, "", text3_color);
    y += 14;
  }
  textFont(doc, 30, word(2, language), x + 70, y + 36, "", text3_color);
}

export function textFont(doc, size, text, x, y, font, c, percent) {
  switch (font) {
    case "bold":
      doc.setFont("Lato-Bold", "normal");
      break;
    case "light":
      doc.setFont("Lato-Light", "normal");
      break;

    default:
      doc.setFont("Lato-Regular", "normal");
      break;
  }
  if (!c) {
    doc.setTextColor("#fffff");
  } else {
    doc.setTextColor(c);
  }
  doc.setFontSize(size);
  if (percent) {
    doc.text(text, x * 6.773, y * 3.81);
  } else {
    doc.text(text, x, y);
  }
}

function formattedAddress(doc, text, rowSize) {
  const rows = getAddressArray(text, rowSize);
  let y = 64;

  rows.forEach((rw) => {
    textFont(doc, 25, rw, 70, y, "", text_color, true);
    y += 4;
  });
}
function getAddressArray(text, rowSize) {
  const arr = text.split(" ");
  let count = -1;
  let rows = [];
  let tempRow = "";
  for (let i = 0; i < arr.length; i++) {
    count = count + arr[i].length + 1;
    if (count < rowSize) {
      tempRow += arr[i] + " ";
    } else {
      count = arr[i].length + 1;
      rows.push(tempRow);
      tempRow = arr[i] + " ";
    }
  }
  if (tempRow !== "") {
    rows.push(tempRow);
  }
  return rows;
}
// ><><><><><><><<><><<><
// BUTTON LOGO
// ><><><><><><><<><><<><
//Validar si el logo tiene espacio para mostrarse y despues pintarlo..
export function botttomLogo(doc, unit) {
  const img = logo_footer;
  const { pageHeight, pageWidth, margin } = positionSacale(doc);
  //Se le cambio el tamaño del logo inferior
  let [logoWidthFooter, logoHeightFooter] = scaleImageSize(doc, img, 70);
  const scala = logoWidthFooter / logoHeightFooter;
  logoHeightFooter = 25;
  logoWidthFooter = logoHeightFooter * scala;
  const centerHeightFooter = pageHeight - logoHeightFooter - margin;
  const centerWidthFooter = (pageWidth - logoWidthFooter) / 2;
  doc.addImage(
    decodeURIComponent(img),
    "PNG",
    centerWidthFooter,
    centerHeightFooter,
    logoWidthFooter,
    logoHeightFooter
  );

  if (["ikonic tower"].includes(unit)) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const generationDate = new Date();
    const day = String(generationDate.getDate()).padStart(2, "0");
    const year = generationDate.getFullYear();
    let formattedDate = ``;
    if (language === "spanish") {
      const month = meses[generationDate.getMonth()];
      formattedDate = `${day}-${month}-${year}`;
    } else {
      const month = months[generationDate.getMonth()];
      formattedDate = `${month}-${day}-${year}`;
    }

    textFont(
      doc,
      20,
      `${word(92, language)} ${formattedDate}`,
      10,
      95,
      "",
      text_color,
      true
    );
  }
}

export function backCover(doc) {
  doc.addPage();
  background(doc, container_two);
  const [imgWidthEnd, imgHeightEnd] = scaleImageSize(doc, logo, 200);

  const { pageWidth, pageHeight } = positionSacale(doc);

  const centerWidth = (pageWidth - imgWidthEnd) / 2;
  const centerHeight = (pageHeight - imgHeightEnd) / 2;

  doc.addImage(
    decodeURIComponent(logo),
    "PNG",
    centerWidth,
    centerHeight,
    imgWidthEnd,
    imgHeightEnd
  );
}

export function unitDetail(doc, agent, properties) {
  const developmentName =
    properties[0].real_estate_development?.name?.toLowerCase() ?? "";
  /*
  Si estamos en el cotizador de zelva44 se activara
  la visualizacion de sus propiedades en unidades
   */
  let detailedUnitView = false;
  let ikonicLegend = false;
  // id de zelva44
  if (["zelva 44", "ikonic tower"].includes(developmentName)) {
    detailedUnitView = true;
    if (["ikonic tower"].includes(developmentName)) {
      ikonicLegend = true;
    }
  }
  properties.forEach((unit) => {
    if (!validateBlueprint(unit)) {
      return;
    }

    const code = unit.code ? unit.code.toUpperCase() : "";
    let total = validate(unit, "total");
    if (total == 0) {
      total += validate(unit, "construction");
      total += validate(unit, "terrace");
    }
    doc.addPage();
    backgroundTwo(doc);
    title(doc, ` ${word(4, language)} ${code}`, 5, 10, title_color);
    agentInfo(doc, agent, 470, 110);
    if (detailedUnitView) {
      detailsUnit(doc, unit, total, ikonicLegend);
    } else {
      sizeUnit(doc, total);
    }
    blueprint(doc, unit);
    botttomLogo(doc, unit?.real_estate_development?.name?.toLowerCase() ?? "");
  });
}
function validateBlueprint(a) {
  let img = false;
  if (a) {
    //Validación de media..
    if (a.media) {
      //Validación plane_image
      if (
        a.media.plane_image &&
        a.media.plane_image.src &&
        a.media.plane_image.src !== ""
      ) {
        img = a.media.plane_image.src;
      }
      //Validación featured_image
      if (
        a.media.featured_image &&
        a.media.featured_image.src &&
        a.media.featured_image.src !== ""
      ) {
        img = a.media.featured_image.src;
      }
    }
  }

  return img;
}

function blueprint(doc, a) {
  let img = false;
  if (a) {
    //Validación de media..
    if (a.media) {
      //Validación plane_image
      if (
        a.media.plane_image &&
        a.media.plane_image.src &&
        a.media.plane_image.src !== ""
      ) {
        img = a.media.plane_image.src;
      }
      //Validación featured_image
      if (
        a.media.featured_image &&
        a.media.featured_image.src &&
        a.media.featured_image.src !== ""
      ) {
        img = a.media.featured_image.src;
      }
    }
  }

  if (img) {
    planeImg(doc, img);
  }
}

export function planeImg(doc, img) {
  img = img.replace(".jpg", ".png");
  const { pageHeight, centerWidth } = positionSacale(doc);
  let [imgFeatureWidt, imgFeatureHeight] = scaleImageSize(
    doc,
    img,
    400,
    400,
    true
  );
  if (imgFeatureHeight > 240) {
    const scala = imgFeatureWidt / imgFeatureHeight;
    imgFeatureHeight = 240;
    imgFeatureWidt = 240 * scala;
  }
  const centerHeightFeature = 20 + (pageHeight - imgFeatureHeight) / 2;
  const centerWidthFeature = 60 + (centerWidth - imgFeatureWidt) / 2;
  doc.addImage(
    decodeURIComponent(img),
    "JPEG",
    centerWidthFeature,
    centerHeightFeature,
    imgFeatureWidt,
    imgFeatureHeight
  );
}

function sizeUnit(doc, total) {
  //textFont(doc, 35, ` ${word(5, language)} ${type}`, 70, 55, "bold", text_color, true);
  textFont(doc, 35, `${word(5, language)}`, 70, 55, "bold", text_color, true);
  textFont(doc, 25, `TOTAL`, 70, 60, "bold", text_color, true);
  textFont(doc, 25, `${total} m²`, 70 + 10, 60, "", text_color, true);
}

/**
 * Pintar los detalles de la unidad, los que salen a la derecha del plano de la unidad
 * para los tipo departamento
 * @param {*} doc documento pdf
 * @param {*} unit datos de la unidad
 * @param {*} total longitud de la unidad en m2
 */

function detailsUnit(doc, unit, total, ikonicLegend) {
  const separator = 9.5;
  const columnSeparator = 15;
  const fontSizeTitle = 25;
  const fontSizeText = 20;
  const baseX = 70;
  let baseY = 55;
  // detalle de unidad
  textFont(
    doc,
    fontSizeTitle,
    `${word(81, language)}`,
    baseX,
    baseY,
    "bold",
    text_color,
    true
  );

  baseY += 5;
  // superficie parcial
  if (unit.building?.construction) {
    textFont(
      doc,
      fontSizeText,
      `${word(87, language)}`,
      baseX,
      baseY,
      "bold",
      text_color,
      true
    );
    textFont(
      doc,
      fontSizeText,
      `${Number(unit.building.construction)} m²`,
      baseX + separator,
      baseY,
      "",
      text_color,
      true
    );
  }
  // superficie terraza
  if (unit.building?.terrace) {
    textFont(
      doc,
      fontSizeText,
      `${word(14, language)}`,
      baseX + columnSeparator,
      baseY,
      "bold",
      text_color,
      true
    );
    textFont(
      doc,
      fontSizeText,
      `${Number(unit.building.terrace)} m²`,
      baseX + columnSeparator + separator,
      baseY,
      "",
      text_color,
      true
    );
  }

  // si hubo superficie parcial y/o terraza bajar en el pdf
  if (unit.building?.construction || unit.building?.terrace) {
    baseY += 5;
  }

  // recamaras
  if (unit.features?.rooms > 0) {
    textFont(
      doc,
      fontSizeText,
      `${word(11, language)}`,
      baseX,
      baseY,
      "bold",
      text_color,
      true
    );
    textFont(
      doc,
      fontSizeText,
      `${Number(unit.features.rooms)}`,
      baseX + separator,
      baseY,
      "",
      text_color,
      true
    );
  }

  // baños
  if (unit.features?.bathrooms > 0) {
    textFont(
      doc,
      fontSizeText,
      `${word(12, language)}`,
      baseX + columnSeparator,
      baseY,
      "bold",
      text_color,
      true
    );
    textFont(
      doc,
      fontSizeText,
      `${Number(unit.features.bathrooms)}`,
      baseX + columnSeparator + separator,
      baseY,
      "",
      text_color,
      true
    );
  }

  // si hubo recamaras y/o baños bajar en el pdf
  if (unit.features?.rooms > 0 || unit.features?.bathrooms > 0) {
    baseY += 5;
  }

  // si hay amenidades
  if (unit.amenities.length > 0) {
    // detalle de amenidades
    textFont(
      doc,
      fontSizeTitle,
      `${word(88, language)}`,
      baseX,
      baseY,
      "bold",
      text_color,
      true
    );
    baseY += 5;

    for (let i = 0; i < unit.amenities.length; i++) {
      let AmenitieName = unit.amenities[i].name?.trim() || "";
      // buscar el nombre de la amenidad en el diccionario
      switch (AmenitieName) {
        case "Terraza":
          AmenitieName = word(14, language);
          break;
        case "Cajones":
          AmenitieName = word(82, language);
          break;
        case "Cuarto de lavado":
          AmenitieName = word(83, language);
          break;
        case "Closet de lavado":
          AmenitieName = word(89, language);
          break;
        case "Bodega":
          AmenitieName = word(84, language);
          break;
      }
      let amenitieAmount = Number(unit.amenities[i].construction.total);
      // si tiene construction total se pone el m2
      const unitName = amenitieAmount ? " m²" : "";
      // si tiene el code se toma como numero de unidades
      amenitieAmount = amenitieAmount || Number(unit.amenities[i].code) || 0;
      const amount = `${amenitieAmount}${unitName}`;
      // si tiene construction total es m2, sino es vacio
      // si el numero es impar se pinta en la columna izquierda
      if ((i + 1) % 2 === 1) {
        // si el numero es impar y es mayor o igual a 3 se pinta en otra fila
        if (i + 1 >= 3) {
          baseY += 5;
        }
        textFont(
          doc,
          fontSizeText,
          `${AmenitieName}`,
          baseX,
          baseY,
          "bold",
          text_color,
          true
        );
        textFont(
          doc,
          fontSizeText,
          amount,
          baseX + separator,
          baseY,
          "",
          text_color,
          true
        );
        // si el numero es par se pinta en la columna derecha
      } else {
        textFont(
          doc,
          fontSizeText,
          `${AmenitieName}`,
          baseX + columnSeparator,
          baseY,
          "bold",
          text_color,
          true
        );
        textFont(
          doc,
          fontSizeText,
          amount,
          baseX + columnSeparator + separator,
          baseY,
          "",
          text_color,
          true
        );
      }
    }
    // una vez termina de pintar las amenidades se pasa a otra fila
    baseY += 5;
  }

  // superficie total
  textFont(
    doc,
    fontSizeTitle,
    `${word(86, language)}`,
    baseX,
    baseY,
    "bold",
    text_color,
    true
  );
  textFont(
    doc,
    fontSizeText,
    `${total} m²`,
    baseX + columnSeparator,
    baseY,
    "",
    text_color,
    true
  );

  if (ikonicLegend) {
    textFont(
      doc,
      fontSizeText,
      `${word(93, language)}`,
      baseX,
      baseY + 10,
      "",
      text_color,
      true
    );
  }
}

//NO  uiwewn mostaar  alwjansw
export function unitDetailPreview(doc, agent, properties) {
  const developmentId = properties[0].real_estate_development?._id ?? "";
  /*
  Si estamos en el cotizador de zelva44 se activara
  la visualizacion de sus propiedades en unidades
   */
  let detailedUnitView = false;
  if (developmentId === "63876dc1575dea781e78f9e6") {
    detailedUnitView = true;
  }
  properties.forEach((unit) => {
    const code = unit.code ? unit.code.toUpperCase() : "";
    const total =
      unit.building && unit.building.total ? unit.building.total : "0";

    doc.addPage();
    backgroundTwo(doc);
    title(doc, ` ${word(4, language)} ${code}`, 5, 10, title_color);
    agentInfo(doc, agent, 470, 110);
    if (detailedUnitView) {
      detailsUnit(doc, unit, total);
    } else {
      sizeUnit(doc, total);
    }
    const imgPlanoWidth = 128.58585858585857;
    const imgPlanoHeight = 270;
    const centerHeightPlano = (380.99999999999994 - imgPlanoHeight) / 2;
    const centerWidthPlano = 10 + (338.65 - imgPlanoWidth) / 2;

    const imgs = `https://res.cloudinary.com/di6cg0ywy/image/upload/v1653599945/arrecife/lote_10_cbzpfi.png`;
    doc.addImage(
      imgs,
      "JPEG",

      centerWidthPlano,
      centerHeightPlano,
      imgPlanoWidth,
      imgPlanoHeight
    );
  });
  botttomLogo(doc);
}

function validate(unit, name) {
  if (!unit) return 0;
  if (!unit.building) return 0;
  if (!unit.building[name]) return 0;
  if (unit.building[name] == "") return 0;
  return unit.building[name];
}
