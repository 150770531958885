import html2canvas from "html2canvas";
import { background, title, textFont } from "./style";
import { word } from "./multi-language";
let doc;
let language = "spanish";

export async function addPictures(document, coordsMap) {
  doc = document;
  await createTwoImages(coordsMap);
}
export async function addPicturesLots(document, coordsMap, lang, structure) {
  doc = document;
  if (lang) language = lang;
  await createTwoImagesLots(coordsMap, structure);
}

async function create(name) {
  return html2canvas(document.querySelector("#" + name), {
    removeContainer: true,
    useCORS: true,
    allowTaint: true,
    windowWidth: Window.innerWidth,
    windowHeight: Window.innerHeight,

    onclone: function(clonedDoc) {
      clonedDoc.getElementById(name).style.opacity = "1.0";
    },
  });
}
async function createTwoImages(coordsMap) {
  if (!coordsMap) return 0;
  if (coordsMap.length == 1) {
    const dt = coordsMap[0];
    embedImages(await create(dt.plane.id), await create(dt.front.id));
    return 0;
  } else if (coordsMap.length > 1) {
    let count = 0;

    for await (const dt of coordsMap) {
      if (count == 0) {
        embedImages(await create(dt.plane.id), await create(dt.front.id));
      } else {
        background(doc);
        title(doc, "UBICACIÓN DE LA OFICINA", 5, 10);
        embedImagesMany(await create(dt.plane.id), await create(dt.front.id));
      }
      count++;
      if (count != coordsMap.length) doc.addPage();
    }
  }
  return 0;
}
async function embedImages(c1, c2) {
  const one_img = render_img(c1, 200, 120, 210);
  render_img(c2, 180, one_img.with + 180, 195);
}

async function embedImagesMany(c1, c2) {
  const one_img = render_img(c1, 200, 120, 150);
  render_img(c2, 180, one_img.with + 180, 135);
}
export async function list_images(items) {
  let imgAll = [];
  items.forEach((e) => {
    let twoImg = { floor: e.floor };
    create(e.front.id).then((dt) => {
      twoImg.front = dt;
      create(e.plane.id).then((dt) => (twoImg.plane = dt));
    });

    imgAll.push(twoImg);
  });
  return imgAll;
}
function render_img(img, size, x, y) {
  const dtaImg = doc.getImageProperties(img);
  const scala = dtaImg.height / dtaImg.width;
  doc.addImage(img, "JPEG", x, y, size, size * scala);
  img = null;
  return {
    with: size,
    height: size * scala,
  };
}

function render_img_doc(doc, imgOne, imgTwo) {
  const sizeOne = calculateSize(doc, imgOne);
  const sizeTwo = calculateSize(doc, imgTwo);
  const totalWithSize = sizeOne.with + sizeTwo.with;
  const pointSize = 677.3 / 2 - totalWithSize / 2;

  doc.addImage(
    imgOne,
    "JPEG",
    pointSize - 15,
    381 / 2 - sizeOne.height / 2 + 15,
    sizeOne.with,
    sizeOne.height
  );
  doc.addImage(
    imgTwo,
    "JPEG",
    pointSize + sizeOne.with + 15,
    381 / 2 - sizeTwo.height / 2 + 15,
    sizeTwo.with,
    sizeTwo.height
  );
}
function calculateSize(doc, img) {
  const dtaImg = doc.getImageProperties(img);
  let scale;
  let width, height;
  if (dtaImg.height < dtaImg.width) {
    scale = dtaImg.height / dtaImg.width;
    width = 300;
    height = 300 * scale;
  } else if (dtaImg.height > dtaImg.width) {
    scale = dtaImg.width / dtaImg.height;
    width = 300 * scale;
    height = 300;
  } else if (dtaImg.height == dtaImg.width) {
    scale = dtaImg.height / dtaImg.width;
    width = 300;
    height = 300 * scale;
  }
  return {
    with: width,
    height: height,
  };
}

export function lotsImg(doc, c1, c2, structure) {
  background(doc);
  // Cambiar idioma... LOT LOCATION --English ---  UBICACÓN DEL LOTE..
  title(doc, word(58, language), 5, 10);
  if (
    ["ikonic tower"].includes(structure?.dataFrontPage?.nameDev?.toLowerCase())
  ) {
    const dateXPosition = 10;
    const dateYPosition = 95;
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const generationDate = new Date();
    const day = String(generationDate.getDate()).padStart(2, "0");
    const year = generationDate.getFullYear();
    let formattedDate = ``;
    if (language === "spanish") {
      const month = meses[generationDate.getMonth()];
      formattedDate = `${day}-${month}-${year}`;
    } else {
      const month = months[generationDate.getMonth()];
      formattedDate = `${month}-${day}-${year}`;
    }

    textFont(
      doc,
      20,
      `${word(92, language)} ${formattedDate}`,
      dateXPosition,
      dateYPosition,
      "",
      "#ffffff",
      true
    );
  }
  //Algoritmo para redimencionar la imagen a un tamaño considerable y que permita acomodarse..
  render_img_doc(doc, c1, c2);
}

//Agregar validación para poder redimencionar la imagen..
async function createTwoImagesLots(coordsMap, structure) {
  if (!coordsMap) return 0;
  doc.addPage();
  if (coordsMap.length == 1) {
    const dt = coordsMap[0];

    lotsImg(
      doc,
      await create(dt.plane.id),
      await create(dt.front.id),
      structure
    );
    //embedImages(await create(dt.plane.id), await create(dt.front.id));
    return 0;
  } else if (coordsMap.length > 1) {
    let count = 0;

    for await (const dt of coordsMap) {
      if (count == 0) {
        lotsImg(
          doc,
          await create(dt.plane.id),
          await create(dt.front.id),
          structure
        );
      } else {
        background(doc);
        //title(doc, "UBICACIÓN DE LA OFICINA", 5, 10);
        lotsImg(
          doc,
          await create(dt.plane.id),
          await create(dt.front.id),
          structure
        );
      }
      count++;
      if (count != coordsMap.length) doc.addPage();
    }
  }
  return 0;
}
