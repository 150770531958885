import request from "../helpers/request";

// obtiene la lista de logs
// solo para usuarios root
export function GetconfigCloudinary(cloudinaryConfig) {
  try {
    let data = {
      cludinaryConfig: cloudinaryConfig
    };

    return new Promise((resolve, reject) => {
      request("/media/uploadCloudinary", data)
        .then(response => {
          resolve({
            Data: response.data
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function CloudinaryUploaderForm(file, cloudinaryConfig) {
  try {
    let formData = new FormData();
    const { signature, timestamp } = await GetconfigCloudinary(
      cloudinaryConfig
    ).then(res => res.Data);
    const CLOUDINARY_URL = `https://api.cloudinary.com/v1_1/${cloudinaryConfig.cloud_name}/image/upload`;
    formData.append("file", file);
    formData.append("api_key", cloudinaryConfig.api_key_public);
    formData.append("timestamp", timestamp);
    formData.append("signature", signature);
    //formData.append('folder', 'quoter');
    // formData.append("eager", "c_pad,h_300,w_400|c_crop,h_200,w_260");
    // formData.append("folder", "signed_upload_demo_form");

    return new Promise((resolve, reject) => {
      fetch(CLOUDINARY_URL, {
        method: "POST",
        body: formData
      })
        .then(response => response.json())
        .then(data => {
          if (data.secure_url !== "") {
            resolve({
              fileInCloudinary: data
            });
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return new Promise((resolve, reject) => {
      reject(err);
    });
  }
}

export async function configCloudinaryUploaderWidget(cloudinaryConfig) {
  try {
    cloudinaryConfig = {
      ...cloudinaryConfig,
      ...{
        upload_widget: {
          source: "uw"
        }
      }
    };
    const { signature, timestamp } = await GetconfigCloudinary(
      cloudinaryConfig
    ).then(res => res.Data);
    const options = {
      cloudName: cloudinaryConfig.cloud_name,
      apiKey: cloudinaryConfig.api_key_public,
      uploadSignatureTimestamp: timestamp,
      uploadSignature: signature,
      cropping: false
      //   folder: "signed_upload_demo_uw",
    };
    return options;
  } catch (err) {
    return new Promise((resolve, reject) => {
      reject(err);
    });
  }
}
