<template>
  <div>
    <div v-if="!realEstateData">
      <v-card width="100vw" height="90vh" elevation="0">
        <v-container fill-height>
          <v-row justify="center" align="center">
            <v-col cols="12" justify="center" align="center">
              <v-progress-circular
                :width="5"
                :size="100"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
            <v-col cols="12" justify="center" align="center">
              <div>
                Cargando desarrollo...
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>

    <v-container v-if="realEstateData" fluid>
      <v-col>
        <v-container>
          <div v-if="realEstateData && realEstateData.name">
            <h1 class="text-center">Desarrollo {{ realEstateData.name }}</h1>
          </div>
        </v-container>
      </v-col>
      <v-col>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-row no-gutters>
                <h3>INFORMACION</h3>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row justify="space-around" no-gutters>
                <v-container>
                  <edit-real-estate-information
                    :developmentInformation="realEstateData"
                  />
                </v-container>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-row no-gutters>
                <h3>IMAGENES Y GALERIAS</h3>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content id="imagenes-galerias">
              <v-row justify="space-around" no-gutters>
                <div class="text-center">
                  <v-btn
                    :disabled="disabledEdit()"
                    outlined
                    v-if="cloudinaryActive === true"
                    @click="cloudinaryActive = false"
                  >
                    editar Cloudinary
                  </v-btn>
                </div>
                <v-container v-if="cloudinaryActive === false">
                  <label>
                    Capital28 utiliza Cloudinary para un mejor control y
                    experiencia de imágenes, por lo que es necesario que se cree
                    una cuenta y llene los siguientes campos.
                  </label>
                  <v-form
                    ref="form"
                    @submit.prevent="handleSubmit"
                    style="margin: 20px"
                  >
                    <v-row align="center" justify="center">
                      <v-col
                        lass="shrink"
                        style="min-width: 220px"
                        cols="12"
                        md="12"
                        sm="12"
                      >
                        <v-text-field
                          :disabled="disabledEdit()"
                          label="Cloud name"
                          v-model="cloudinaryConfig.cloud_name"
                          :rules="rules"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        lass="shrink"
                        style="min-width: 220px"
                        cols="12"
                        md="12"
                        sm="12"
                      >
                        <v-text-field
                          :disabled="disabledEdit()"
                          label="API Key"
                          v-model="cloudinaryConfig.api_key_public"
                          :rules="rules"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        lass="shrink"
                        style="min-width: 220px"
                        cols="12"
                        md="12"
                        sm="12"
                      >
                        <v-text-field
                          :disabled="disabledEdit()"
                          label="API Secret"
                          v-model="cloudinaryConfig.api_key_private"
                          :rules="rules"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        lass="shrink"
                        style="min-width: 220px"
                        cols="12"
                        md="12"
                        sm="12"
                      >
                        <v-text-field
                          :disabled="disabledEdit()"
                          label="API Environment variable"
                          v-model="cloudinaryConfig.api_environment_variable"
                          :rules="rules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row justify="end">
                      <v-col>
                        <v-btn
                          :disabled="disabledEdit()"
                          type="submit"
                          color="success"
                          :loading="loading"
                        >
                          Guardar
                        </v-btn>

                        <v-btn
                          :disabled="disabledEdit()"
                          outlined
                          class="ma-2"
                          @click="cloudinaryActive = true"
                        >
                          Cancelar
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-btn
                          :disabled="disabledEdit()"
                          href="https://cloudinary.com/users/register/free"
                          target="_blank"
                          left
                          color="primary"
                        >
                          Registrate en Cloudinary
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
                <v-container v-if="cloudinaryActive === true">
                  <v-form ref="form" action="">
                    <v-row align="center" justify="center">
                      <v-col cols="12" sm="12">
                        <h3 class="pt-6">Imagen Destacada</h3>
                        <v-row align="center" justify="center">
                          <v-col>
                            <v-file-input
                              :disabled="disabledEdit()"
                              accept="image/png, image/jpeg, image/bmp"
                              label="Agregar nueva imagen destacada"
                              @change="handleFilesMediaFeature"
                              filled
                              class="ma-8"
                              background-color="#D5D8DC"
                              prepend-icon="mdi-cloud-upload"
                              full-width
                              height="65px"
                            ></v-file-input>
                          </v-col>
                        </v-row>

                        <v-img
                          :lazy-src="previewMediaFeature"
                          :src="previewMediaFeature"
                        ></v-img>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="12" lg="12" sm="12">
                        <h3 class="pt-6">Galería</h3>
                        <v-simple-table
                          :dense="dense"
                          :fixed-header="fixedHeader"
                          :height="height"
                        >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Alt Text</th>
                                <th class="text-left">Nombre</th>
                                <th class="text-left">Cloudinary ID</th>
                                <th class="text-left">SRC</th>
                                <!-- <th class="text-left"></th> -->
                                <th class="text-left"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in galleryData" :key="item.name">
                                <td>{{ item.alt_text }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.cloudinary_id }}</td>
                                <td>{{ item.src }}</td>

                                <td>
                                  <v-icon
                                    small
                                    @click="deleteItemGallery(item)"
                                  >
                                    mdi-delete
                                  </v-icon>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>

                      <v-col cols="6" lg="6" sm="6"></v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel :disabled="role === 'coordinator'">
            <v-expansion-panel-header>
              <v-row no-gutters>
                <h3>AGREGAR PROPIEDADES</h3>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row justify="space-around" no-gutters>
                <v-container>
                  <h4>Importar propiedades con excel</h4>
                  <import-properties-excel
                    :developmentsCode="realEstateCode"
                    :developmentsName="realEstateName"
                    :developmentsId="realEstateID"
                  ></import-properties-excel>
                </v-container>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            :disabled="activeQuote === 'off' || role === 'coordinator'"
          >
            <v-expansion-panel-header>
              <v-row no-gutters>
                <h3>COTIZADOR</h3>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row justify="space-around" no-gutters>
                <v-container>
                  <v-form ref="form" action="">
                    <h3>Detalles</h3>
                    <v-row align="center" justify="center">
                      <v-col
                        lass="shrink"
                        style="min-width: 220px"
                        cols="12"
                        md="6"
                        sm="6"
                      >
                        <label>¿Precios incluyen IVA?</label>
                        <v-radio-group v-model="includeIva" row>
                          <v-radio
                            label="Si"
                            :value="true"
                            color="#042956"
                          ></v-radio>
                          <v-radio
                            label="No"
                            :value="false"
                            color="#042956"
                          ></v-radio>
                        </v-radio-group>
                        <div>
                          <v-text-field
                            :disabled="disabledEdit()"
                            v-model="iva"
                            prefix="%"
                            type="number"
                            step="0.01"
                            color="#042956"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col
                        lass="shrink"
                        style="min-width: 220px"
                        cols="12"
                        md="6"
                        sm="6"
                      >
                      </v-col>
                    </v-row>
                    <h3>Preview PDF</h3>
                    <!--Vista del pdf -->

                    <v-row justify="center">
                      <v-col
                        class="shrink"
                        style="min-width: 220px"
                        cols="12"
                        md="6"
                        sm="6"
                      >
                        <preview-print-pdf
                          v-if="typeOfQuote == 'one'"
                          :hideCurrency="styles_pdf.hideCurrency"
                          :bgOne="styles_pdf.container_one"
                          :bgTwo="styles_pdf.container_two"
                          :textColor="styles_pdf.text_color"
                          :titleColor="styles_pdf.title_color"
                          :titleTwoColor="styles_pdf.title2_color"
                          :textThreeColor="styles_pdf.text3_color"
                          :imgEstate="previewMediaFeature"
                          :propertyData="listPropertys"
                          :logoDesing="styles_pdf.logo"
                          :imgEstateDesing="styles_pdf.featured_image"
                          :realEstateGroupLogoDesing="
                            styles_pdf.realEstateGroupLogo
                          "
                          :logoFooter="styles_pdf.logo_footer"
                          :defaultActive="defaultActive"
                        />

                        <preview-advanced-print-pdf
                          v-if="typeOfQuote == 'two'"
                          :hideCurrency="styles_pdf.hideCurrency"
                          :bgOne="styles_pdf.container_one"
                          :bgTwo="styles_pdf.container_two"
                          :textColor="styles_pdf.text_color"
                          :titleColor="styles_pdf.title_color"
                          :titleTwoColor="styles_pdf.title2_color"
                          :textThreeColor="styles_pdf.text3_color"
                          :imgEstate="previewMediaFeature"
                          :propertyData="listPropertys"
                          :logoDesing="styles_pdf.logo"
                          :imgEstateDesing="styles_pdf.featured_image"
                          :realEstateGroupLogoDesing="
                            styles_pdf.realEstateGroupLogo
                          "
                          :logoFooter="styles_pdf.logo_footer"
                          :defaultActive="defaultActive"
                          :unitTypes="unitType"
                        />
                      </v-col>
                      <v-col
                        lass="shrink"
                        style="min-width: 220px"
                        cols="12"
                        md="6"
                        sm="6"
                      >
                        <h1 class="text-center mb-4">CONFIGURACIÓN</h1>
                        <v-row align="center" justify="center">
                          <!-- <h1 class="text-center">Base</h1> -->
                          <v-col
                            lass="shrink"
                            style="min-width: 220px"
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <color-picker-selected
                              label="Fondo Primario"
                              :defColor="styles_pdf.container_one"
                              @setColorSelected="
                                value => (styles_pdf.container_one = value)
                              "
                            >
                            </color-picker-selected>
                          </v-col>
                          <v-col
                            lass="shrink"
                            style="min-width: 220px"
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <color-picker-selected
                              label="Fondo Secundario"
                              :defColor="styles_pdf.container_two"
                              @setColorSelected="
                                value => (styles_pdf.container_two = value)
                              "
                            >
                            </color-picker-selected>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            lass="shrink"
                            style="min-width: 220px"
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <color-picker-selected
                              label="Color del título"
                              :defColor="styles_pdf.title_color"
                              @setColorSelected="
                                value => (styles_pdf.title_color = value)
                              "
                            >
                            </color-picker-selected>
                          </v-col>
                          <v-col
                            lass="shrink"
                            style="min-width: 220px"
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <color-picker-selected
                              label="Color del título 2"
                              :defColor="styles_pdf.title2_color"
                              :disableInput="false"
                              @setColorSelected="
                                value => (styles_pdf.title2_color = value)
                              "
                            >
                            </color-picker-selected>
                          </v-col>
                          <v-col
                            lass="shrink"
                            style="min-width: 220px"
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <color-picker-selected
                              label="Color del título 3"
                              :defColor="styles_pdf.text3_color"
                              @setColorSelected="
                                value => (styles_pdf.text3_color = value)
                              "
                            >
                            </color-picker-selected>
                          </v-col>
                          <v-col
                            lass="shrink"
                            style="min-width: 220px"
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <color-picker-selected
                              label="Color del texto"
                              :defColor="styles_pdf.text_color"
                              @setColorSelected="
                                value => (styles_pdf.text_color = value)
                              "
                            >
                            </color-picker-selected>
                          </v-col>
                          <!-- Ocultar la divisa -->
                          <v-col
                            lass="shrink"
                            style="min-width: 220px"
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <v-checkbox
                              v-if="typeOfQuote == 'one'"
                              v-model="styles_pdf.hideCurrency"
                              label="Ocultar Divisa"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <!--  Imagenes: logo Desarrollo, logo Inmobiliria, Imagen Destacada -->
                        <ImagePickerSelected
                          sizeRecommended="Tamaño recomendado: 600 x 350 px, png con transparencia"
                          label="Logo desarrollo"
                          :previousImage="styles_pdf.logo"
                          :defaultActive="defaultActive"
                          @setNewImage="value => (styles_pdf.logo = value)"
                        >
                        </ImagePickerSelected>

                        <ImagePickerSelected
                          v-if="typeOfQuote == 'one'"
                          sizeRecommended="Tamaño recomendado: 600 x 350 px, png con transparencia"
                          label="Logo comercializadora (opcional)"
                          :previousImage="styles_pdf.realEstateGroupLogo"
                          :defaultActive="defaultActive"
                          @setNewImage="
                            value => (styles_pdf.realEstateGroupLogo = value)
                          "
                        >
                        </ImagePickerSelected>
                        <ImagePickerSelected
                          sizeRecommended="Orientación horizontal de alta calidad (png o jpg)"
                          label="Imagen destacada desarrollo"
                          :previousImage="styles_pdf.featured_image"
                          :defaultActive="defaultActive"
                          @setNewImage="
                            value => (styles_pdf.featured_image = value)
                          "
                        >
                        </ImagePickerSelected>

                        <ImagePickerSelected
                          sizeRecommended="Tamaño recomendado: 600 x 350 px, png con transparencia"
                          label="Logo pie de pagina (opcional)"
                          :previousImage="styles_pdf.logo_footer"
                          :defaultActive="defaultActive"
                          @setNewImage="
                            value => (styles_pdf.logo_footer = value)
                          "
                        >
                        </ImagePickerSelected>
                      </v-col>
                    </v-row>

                    <!-- Botones de guardar y valores por default -->
                    <v-row>
                      <v-col>
                        <v-btn color="#262D3C" @click="defaultStyle" dark>
                          Valores por default
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-btn
                          color="success"
                          @click="handleUpdateMiscellaneous"
                          dark
                        >
                          {{ formTitleQuote }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template>
                <v-row no-gutters>
                  <h3>REGLAS DE PRECIOS</h3>
                </v-row>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row justify="space-around" no-gutters>
                <v-container>
                  <v-form ref="form" action="">
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="6" sm="6">
                        <v-select
                          :items="listPropertyCode"
                          v-model="pricing_rules.propertys_code"
                          clearable
                          chips
                          color="#042956"
                          prepend-icon="mdi-city"
                          item-text="name"
                          label="Propiedades (Por defecto: todas)"
                          multiple
                          deletable-chips
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                              <span>{{ item.name }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text caption"
                              >(+{{
                                pricing_rules.propertys_code.length - 1
                              }}
                              otros)</span
                            >
                          </template>
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-select
                          :items="sltprocessType"
                          v-model="pricing_rules.process_type"
                          item-text="name"
                          item-value="value"
                          label="Tipo de contrato"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="12" sm="12">
                        <v-text-field
                          v-model="pricing_rules.description"
                          label="TÍtulo"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="6" sm="6">
                        <v-select
                          :items="sltmethodPayment"
                          v-model="pricing_rules.method_payment"
                          item-text="name"
                          item-value="value"
                          label="Elige tu forma de pago"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          label="Descripción de la forma de pago"
                          v-model="pricing_rules.description_method_payment"
                          type="text"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          label="Financiamiento"
                          v-model="pricing_rules.financing"
                          prefix="%"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          label="Intereses"
                          v-model="pricing_rules.financing_interest"
                          prefix="%"
                          type="number"
                          :disabled="
                            pricing_rules.method_payment !==
                              'financing-interest'
                          "
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          label="Meses acordados"
                          v-model="pricing_rules.months_agreed"
                          prefix="#"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6"></v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          :label="mpDepositLabel"
                          v-model="pricing_rules.guarantee_deposit"
                          prefix="$"
                          type="number"
                          :disabled="pricing_rules.propertys_code.length > 1"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          label="Descripción del apartado"
                          v-model="pricing_rules.description_guarantee_deposit"
                          type="text"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          label="Enganche"
                          v-model="pricing_rules.percentage_down_payment"
                          prefix="%"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          label="Descripción del enganche"
                          v-model="
                            pricing_rules.description_percentage_down_payment
                          "
                          type="text"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          label="Finiquito contra entrega"
                          v-model="pricing_rules.termination_payment"
                          prefix="%"
                          type="number"
                          :disabled="pricing_rules.propertys_code.length > 1"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          label="Descripción del finiquito de entrega"
                          v-model="
                            pricing_rules.description_termination_payment
                          "
                          type="text"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="6" sm="6">
                        <v-switch
                          v-model="pricing_rules.apply_discount"
                          :input-value="pricing_rules.apply_discount"
                          label="Aplicar descuento(s)"
                          @change="value => changeActiveDiscount(value)"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          label="Cantidad de descuentos permitidos"
                          v-model="pricing_rules.number_apply_discount"
                          prefix="#"
                          type="number"
                          :disabled="pricing_rules.apply_discount !== true"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="12" sm="12">
                        <v-select
                          :items="listDiscount"
                          v-model="pricing_rules.discounts"
                          clearable
                          chips
                          color="#042956"
                          prepend-icon="mdi-sale"
                          item-text="name"
                          label="Agregar descuento(s)"
                          :disabled="pricing_rules.apply_discount !== true"
                          multiple
                          :counter="pricing_rules.number_apply_discount"
                          v-on:input="limiter"
                          deletable-chips
                        >
                          <template v-slot:prepend-item>
                            <v-list-item ripple @click="toggle">
                              <v-list-item-action>
                                <v-icon
                                  :color="
                                    pricing_rules.discounts.length > 0
                                      ? 'indigo darken-4'
                                      : ''
                                  "
                                  >{{ icon }}</v-icon
                                >
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>Todos</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                              <span>{{ item.name }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text caption"
                              >(+{{
                                pricing_rules.discounts.length - 1
                              }}
                              otros)</span
                            >
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row justify="end">
                      <v-col>
                        <v-btn
                          color="success"
                          @click="addRulePrice"
                          dark
                          class="mb-2"
                        >
                          {{ formTitlePR }}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="12" lg="12" sm="10">
                        <v-simple-table
                          :dense="dense"
                          :fixed-header="fixedHeader"
                          :height="height"
                        >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Disponible</th>
                                <th class="text-left">Tipo de contratos</th>
                                <th class="text-left">Propiedades</th>
                                <th class="text-left">Descripción</th>
                                <th class="text-left">Forma de pago</th>
                                <th class="text-left">Enganche</th>
                                <th class="text-left">Descuento</th>
                                <th class="text-left"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="item in real_estate_pricing_rules"
                                :key="item._id"
                              >
                                <td>
                                  <v-checkbox
                                    :input-value="disponibility(item.status)"
                                    color="indigo darken-3"
                                    light
                                    hide-details
                                    @change="changeDisponibility(item)"
                                  ></v-checkbox>
                                </td>
                                <td>
                                  {{ valueProcessType[item.process_type] }}
                                </td>
                                <td>{{ item.propertys_code.toString() }}</td>
                                <td>{{ item.description }}</td>
                                <td>
                                  {{ valueMethodPaymet[item.method_payment] }}
                                </td>
                                <td>
                                  {{
                                    item.percentage_down_payment
                                      ? `${item.percentage_down_payment} %`
                                      : "-"
                                  }}
                                </td>
                                <td>
                                  {{ getDiscountAssigned(item.discounts) }}
                                </td>
                                <td>
                                  <v-icon
                                    @click="editItemPR(item)"
                                    small
                                    class="mr-2"
                                  >
                                    mdi-pencil
                                  </v-icon>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template>
                <v-row no-gutters>
                  <h3>DESCUENTOS</h3>
                </v-row>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row justify="space-around" no-gutters>
                <v-container>
                  <v-form ref="form" action="">
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="6" sm="6">
                        <v-select
                          :items="listPropertyCode"
                          v-model="add_discounts.propertys_code"
                          clearable
                          chips
                          color="#042956"
                          prepend-icon="mdi-city"
                          item-text="name"
                          label="Propiedades (Por default: todas)"
                          multiple
                          deletable-chips
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                              <span>{{ item.name }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text caption"
                              >(+{{
                                pricing_rules.propertys_code.length - 1
                              }}
                              otros)</span
                            >
                          </template>
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-select
                          :items="sltmethodDiscount"
                          v-model="add_discounts.method_discount"
                          item-text="name"
                          item-value="value"
                          label="Elije el tipo de descuento"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          label="Descuento"
                          v-model="add_discounts.discount"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          label="Descripción del descuento"
                          v-model="add_discounts.description_method_discount"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="6" sm="6">
                        <v-select
                          :items="sltmethodCondition"
                          v-model="add_discounts.condition"
                          item-text="name"
                          item-value="value"
                          label="Condicion para las unidades"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          label="Numero Unidades"
                          v-model="add_discounts.properties_apply_discount"
                          prefix="#"
                          type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row justify="end">
                      <v-col>
                        <v-btn
                          color="success"
                          @click="addDiscounts"
                          dark
                          class="mb-2"
                        >
                          {{ formTitleDis }}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="12" lg="12" sm="10">
                        <v-simple-table
                          :dense="dense"
                          :fixed-header="fixedHeader"
                          :height="height"
                        >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Descripcion</th>
                                <th class="text-left">Tipo de Descuento</th>
                                <th class="text-left">Descuento</th>
                                <th class="text-left"></th>
                                <!-- <th class="text-left"></th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="item in real_estate_discount"
                                :key="item.process_type"
                              >
                                <td>{{ item.description_method_discount }}</td>
                                <td>
                                  {{
                                    valueMethodDiscount[item.method_discount]
                                  }}
                                </td>
                                <td>
                                  {{
                                    printDiscountValue(
                                      item.discount,
                                      item.method_discount
                                    )
                                  }}
                                </td>
                                <td>
                                  <v-icon
                                    @click="editItemDis(item)"
                                    small
                                    class="mr-2"
                                  >
                                    mdi-pencil
                                  </v-icon>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel :disabled="role === 'coordinator'">
            <v-expansion-panel-header>
              <v-row no-gutters>
                <h3>ETIQUETAS DE SEO</h3>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row justify="space-around" no-gutters>
                <v-container>
                  <v-form
                    ref="form"
                    @submit.prevent="handleUpdateMiscellaneous"
                  >
                    <v-row>
                      <v-col cols="12" sm="6">
                        <h3>Slug</h3>
                        <v-text-field
                          :disabled="disabledEdit()"
                          v-model="slugWebsite"
                          label="Slug Website"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <h3>Alias</h3>
                        <v-text-field
                          :disabled="disabledEdit()"
                          v-model="aliases"
                          label="Aliases"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <h3>Title</h3>
                        <v-text-field
                          :disabled="disabledEdit()"
                          v-model="seoTitle"
                          label="Title"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <h3>Description</h3>
                        <v-text-field
                          :disabled="disabledEdit()"
                          v-model="seoDescription"
                          label="Description"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <h3>Metas</h3>
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          :disabled="disabledEdit()"
                          v-model="metadata.property"
                          label="Property"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          :disabled="disabledEdit()"
                          v-model="metadata.content"
                          label="Meta Content"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row justify="end">
                      <v-col>
                        <v-btn
                          :disabled="disabledEdit()"
                          color="primary"
                          @click="save"
                          dark
                          class="mb-2"
                          >{{ formTitle }}</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="12" lg="12" sm="10">
                        <v-simple-table
                          :dense="dense"
                          :fixed-header="fixedHeader"
                          :height="height"
                        >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Meta Property</th>
                                <th class="text-left">Meta Content</th>
                                <th class="text-left"></th>
                                <th class="text-left"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in seoMetas" :key="item.name">
                                <td>{{ item.property }}</td>
                                <td>{{ item.content }}</td>
                                <td>
                                  <v-icon
                                    @click="editItem(item)"
                                    small
                                    class="mr-2"
                                  >
                                    mdi-pencil
                                  </v-icon>
                                </td>

                                <td>
                                  <v-icon small @click="deleteItem(item)">
                                    mdi-delete
                                  </v-icon>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="12" sm="12">
                        <h3>Schema Script</h3>
                        <br />
                        <v-textarea
                          :disabled="disabledEdit()"
                          filled
                          name="input-7-4"
                          label="Schema Script"
                          :value="schemaScript"
                          v-model="schemaScript"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <h3>Main description</h3>
                        <br />
                        <v-textarea
                          :disabled="disabledEdit()"
                          filled
                          name="input-7-4"
                          label="Main Description"
                          :value="maindescrption"
                          v-model="maindescrption"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-flex justify-end>
                      <div v-show="responseMessage">
                        <v-alert
                          border="top"
                          color="green lighten-1"
                          type="success"
                          icon="mdi-check"
                          class="mt-3"
                          dark
                        >
                          {{ responseMessage }}
                        </v-alert>
                      </div>

                      <div v-show="serverError">
                        <v-alert
                          border="top"
                          color="red lighten-1"
                          type="error"
                          icon="mdi-alert-circle-outline"
                          class="mt-3"
                          dark
                        >
                          {{ serverError }}
                        </v-alert>
                      </div>
                    </v-flex>
                    <br />
                    <v-btn
                      :disabled="disabledEdit()"
                      type="submit"
                      color="success"
                      :loading="loading"
                      >Guardar</v-btn
                    >
                  </v-form>
                </v-container>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  GetRealEstateDevelopmentDetail,
  UpdateRealEstateDevelopment
} from "@/api/real-estate-development-api.js";
import { GetProperties } from "@/api/property-api.js";
import {
  CreateRulesPricing,
  ListRulesPricing,
  UpdatePricingRule,
  ListDiscount,
  CreateDiscount,
  UpdateDiscount
} from "@/api/real-estate-pricing-rules-api.js";
//configCloudinaryUploaderWidget
import { CloudinaryUploaderForm } from "@/api/media-api.js";
import PreviewPrintPdf from "./real-estate-edits-components/PreviewPrintPdf.vue";
import PreviewAdvancedPrintPdf from "./real-estate-edits-components/PreviewAdvancedPrintPdf.vue";
import EditRealEstateInformation from "./real-estate-edits-components/EditRealEstateInformation.vue";
export default {
  components: {
    ImportPropertiesExcel: () =>
      import("@/components/real-estate-development/ImportPropertiesExcel.vue"),
    ColorPickerSelected: () =>
      import(
        "@/components/real-estate-development/quote/ColorPickerSelected.vue"
      ),
    ImagePickerSelected: () =>
      import(
        "@/components/real-estate-development/quote/ImagePickerSelected.vue"
      ),
    PreviewPrintPdf,
    PreviewAdvancedPrintPdf,
    EditRealEstateInformation
  },
  data: () => ({
    unitType: "office",
    typeOfQuote: "one",
    tab: null,
    itemsPDF: ["Sección 1", "Sección 2", "Sección 3", "Sección 4"],
    rules: [value => !!value || "Required."],
    responseMessage: "",
    serverError: "",
    messageError: "",
    loading: false,
    activeRequest: false,
    realEstateData: "",
    //Formulario Gallery
    valid: true,
    imageUrl: "",
    alt_img: "",
    galleryData: [],
    galleryItems: {
      alt_text: "",
      cloudinary_id: "",
      name: "",
      src: "",
      type: ""
    },
    descriptionRealEstate: "",
    dense: false,
    fixedHeader: true,
    height: 300,
    editedIndexG: -1,
    //SEO
    seoTitle: "",
    seoDescription: "",
    quoteTitle: "",
    quoteDescription: "",
    seoData: {},
    schemaScript: "",
    mobileOrder: "",
    mappingCoordsStructure: [],
    seoMetas: [],
    slugWebsite: "",
    alias: [],
    aliases: "",
    maindescrption: "",
    metadata: {
      property: "",
      content: ""
    },
    styles_view: {
      element: "",
      style: ""
    },
    listPropertyCode: [],
    listPropertys: null,
    editedIndex: -1,
    realEstateID: "",
    realEstateName: "",
    sltprocessType: [
      // {
      //   name: "Renta",
      //   value: "rent"
      // },
      {
        name: "Venta",
        value: "sale"
      },
      {
        name: "Preventa",
        value: "pre-sale"
      }
    ],
    valueProcessType: {
      sale: "Venta",
      "pre-sale": "Preventa"
    },
    sltmethodPayment: [
      {
        name: "Financiamiento",
        value: "financing"
      },
      {
        name: "Financiamiento con intereses (sobre saldos insolutos)",
        value: "financing-interest"
      },
      {
        name: "Contado",
        value: "counted"
      }
    ],
    valueMethodPaymet: {
      financing: "Financiamiento",
      "financing-interest":
        "Financiamiento con intereses (sobre saldos insolutos)",
      counted: "Contado"
    },
    sltmethodDiscount: [
      {
        name: "Porcentaje",
        value: "percentage"
      },
      {
        name: "Fijo",
        value: "fixed"
      }
    ],
    valueMethodDiscount: {
      percentage: "Porcentaje",
      fixed: "Fijo"
    },
    //enum: ['equals', 'greater', 'less', ''],
    sltmethodCondition: [
      {
        name: "Igual",
        value: "equals"
      },
      {
        name: "Mayor",
        value: "greater"
      },
      {
        name: "Menor",
        value: "less"
      }
    ],
    real_estate_pricing_rules: [],
    editedIndexPR: -1,
    real_estate_discount: [],
    editedIndexDis: -1,
    listDiscount: [],
    pricing_rules: {
      process_type: "",
      description: "",
      method_payment: "",
      financing: "",
      financing_interest: "",
      percentage_down_payment: "",
      description_percentage_down_payment: "",
      guarantee_deposit: "",
      description_guarantee_deposit: "",
      months_agreed: "",
      montly_payment: "",
      description_method_payment: "",
      termination_payment: "",
      description_termination_payment: "",
      propertys_code: [],
      apply_discount: false,
      number_apply_discount: "0",
      discounts: []
    },
    add_discounts: {
      method_discount: "",
      discount: "",
      months_discount: "",
      description_method_discount: "",
      properties_apply_discount: "",
      condition: "",
      propertys_code: []
    },
    styles_pdf: {
      container_one: "#262D3C",
      container_two: "#FFFFFF",
      //container_separator: "#000000",
      text_color: "#FFFFFF",
      title_color: "#262D3C",
      title2_color: "#262D3C",
      text3_color: "#262D3C",
      featured_image: null,
      logo: null,
      realEstateGroupLogo: null,
      logo_footer: null,
      //Se agrega la opcion de poder ocultar la divisa
      hideCurrency: false
    },
    //Activar variable para obtener datos por default
    defaultActive: false,
    updatePR: false,
    updateDis: false,
    successMessage: "",
    errorMessage: "",
    includeIva: false,
    iva: 0,

    activeQuote: "off",
    cloudinaryActive: false,
    cloudinaryConfig: {
      name: "cloudinary",
      cloud_name: "",
      api_key_public: "",
      api_key_private: "",
      api_environment_variable: ""
    },
    configDomain: "",
    configApiKeys: [],
    mediaLogo: {
      name: "",
      title: "",
      src: "",
      cloudinary_id: ""
    },
    previewLogo: "",
    previewMediaFeature: "",
    previewMediaStructure: "",
    brokerInformation: [],
    developmentType: "",
    role: localStorage.getItem("user_role")
  }),
  mounted() {},
  methods: {
    //Reglas de precio...
    //Cambiar la disponibilidad
    disponibility(value) {
      if (value == 1) return true;
      return false;
    },
    //Función que cambia la disponibilidad en la base de datos...
    changeDisponibility(item) {
      const status = item.status == 1 ? 2 : 1;
      //Si todo sale bien se cambia el status en los datos de la tabla y de lo contrario no se cambia nada..
      this.$snotify.async(
        "Actualizando Regla de Precio",
        "Actualizando ...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              UpdatePricingRule({ status }, item._id)
                .then(() => {
                  item.status = status;
                  resolve({
                    title: "Regla de precio",
                    body: `Actualizado correctamente`,
                    config: {
                      closeOnClick: true,
                      titleMaxLength: 30,
                      timeout: 2000
                    }
                  });
                })
                .catch(err => {
                  reject({
                    title: "Error!!!",
                    body: `Ocurrio un problema en el proceso. ${err}`,
                    config: {
                      closeOnClick: true,
                      timeout: 4000
                    }
                  });
                })
                .finally();
            }, 500);
          })
      );
    },
    //Fin del cambio de estatus de la regla de precio

    disabledEdit() {
      if (this.role === null) return true;
      if (this.role === "coordinator") return true;
      if (this.role === "admin") return false;
      return true;
    },
    defaultStyle() {
      var resultado = window.confirm(
        "¿Seguro que quiere restaurar los valores por default?"
      );
      if (resultado === true) {
        this.defaultActive = true;

        this.styles_pdf = {
          container_one: "#262D3C",
          container_two: "#FFFFFF",
          //container_separator: "#000000",
          text_color: "#FFFFFF",
          title_color: "#262D3C",
          title2_color: "#262D3C",
          text3_color: "#262D3C",
          featured_image: null,
          logo: null,
          realEstateGroupLogo: null,
          logo_footer: null,
          //Se agrega la opcion de poder ocultar la divisa
          hideCurrency: false
        };
        setTimeout(() => {
          this.defaultActive = false;
        }, 1000);
      }
      //alert("¿Seguro que quiere restaurar los valores por default?");
    },
    async consultDetails() {
      let self = this;
      await GetRealEstateDevelopmentDetail(this.realEstateCode)
        .then(function(response) {
          const development = response.RealEstateDevelopment;
          self.loading = false;
          self.realEstateData = development;
          self.realEstateID = development._id;
          self.realEstateName = development.name;
          //Typo de unidad...
          if (development.unit_types) {
            self.unitType = development.unit_types;
          }

          if (development.miscellaneous) {
            if (development.miscellaneous.seo) {
              if (development.miscellaneous.seo.title) {
                self.seoTitle = development.miscellaneous.seo.title;
              }
              if (development.miscellaneous.seo.description) {
                self.seoDescription = development.miscellaneous.seo.description;
              }
              if (development.miscellaneous.seo.metas) {
                self.seoMetas = development.miscellaneous.seo.metas;
              }
              if (development.miscellaneous.seo.schema_script) {
                self.schemaScript = JSON.stringify(
                  development.miscellaneous.seo.schema_script
                );
              }
            }
            if (development.miscellaneous.mobile_order) {
              self.mobileOrder = development.miscellaneous.mobile_order;
            }
            if (development.miscellaneous.mapping_coords_structure) {
              self.mappingCoordsStructure =
                development.miscellaneous.mapping_coords_structure;
            }
            if (development.miscellaneous.quote) {
              //Cotizador antiguo
              if (
                development.miscellaneous.quote.active &&
                development.miscellaneous.quote.active === "on"
              ) {
                self.activeQuote = development.miscellaneous.quote.active;
                self.typeOfQuote = "one";
              }

              //Cotizador nuevo
              if (
                development.miscellaneous.quote.active_two &&
                development.miscellaneous.quote.active_two === "on"
              ) {
                self.activeQuote = development.miscellaneous.quote.active_two;
                self.typeOfQuote = "two";
              }

              if (
                development.miscellaneous.quote.vat &&
                development.miscellaneous.quote.vat > 0
              ) {
                self.includeIva = development.miscellaneous.quote.include_vat;
                self.iva = development.miscellaneous.quote.vat;
              }
              if (development.miscellaneous.quote.styles_pdf) {
                self.styles_pdf = {
                  ...self.styles_pdf,
                  ...development.miscellaneous.quote.styles_pdf
                };
              }
            }
            if (development.miscellaneous.main_description) {
              self.maindescrption = development.miscellaneous.main_description;
            }
            if (development.miscellaneous.website) {
              if (development.miscellaneous.website.slug) {
                self.slugWebsite = development.miscellaneous.website.slug;
              }
            }
            if (development.miscellaneous.aliases) {
              self.aliases = development.miscellaneous.aliases;
            }
          }
          if (development.media) {
            if (development.media.featured_image) {
              self.previewMediaFeature = development.media.featured_image.src;
            }
            if (development.media.logo) {
              self.previewLogo = development.media.logo.src;
              self.mediaLogo = {
                ...self.mediaLogo,
                ...development.media.logo
              };
            }
            if (development.media.gallery) {
              self.galleryData = development.media.gallery;
            }
            if (development.media.featured_image) {
              if (development.media.featured_image.src) {
                self.previewMediaFeature = development.media.featured_image.src;
              }
            }
            //previewMediaStructure
            if (development.media.structure) {
              if (development.media.structure.src) {
                self.previewMediaStructure = development.media.structure.src;
              }
            }
          }
          if (development.config) {
            if (
              development.config.api_keys &&
              development.config.api_keys.length > 0
            ) {
              development.config.api_keys.forEach(keys => {
                self.configApiKeys.push(keys);
                if (keys.name === "cloudinary") {
                  self.cloudinaryActive = true;
                  self.cloudinaryConfig = {
                    ...self.cloudinaryConfig,
                    ...keys
                  };
                }
              });
            }
            if (development.config.server) {
              if (development.config.server.domain) {
                self.configDomain = development.config.server.domain;
              }
            }
          }
          //real estate information
          if (development.development_structure) {
            if (development.development_structure.type) {
              self.developmentType = development.development_structure.type;
            }
          }
        })
        .catch(error => error)
        .finally();
    }, //api
    async consultProperties() {
      let self = this;
      GetProperties(this.realEstateCode)
        .then(res => {
          self.listPropertys = res.Property;
          res.Property.forEach(property => {
            self.listPropertyCode.push({
              name: property.code,
              pricing: property.pricing,
              status: property.status,
              ID: property["_id"]
            });
          });
        })
        .catch(error => error)
        .finally();
    },
    async consultPricingRules() {
      let self = this;
      await ListRulesPricing(this.realEstateID)
        .then(res => {
          if (res || res.length > 0) {
            res.PricingRules.map(pricingRule => {
              self.real_estate_pricing_rules.push(pricingRule);
            });
          }
        })
        .catch(error => error)
        .finally();
    },
    async consultDiscount() {
      let self = this;
      await ListDiscount(this.realEstateID)
        .then(res => {
          if (res || res.length > 0) {
            res.Discount.map(discount => {
              self.real_estate_discount.push(discount);
              self.listDiscount.push({
                name: discount.description_method_discount,
                value: discount["_id"]
              });
            });
          }
        })
        .catch(error => error)
        .finally();
    },
    setActualRealEstate(code) {
      this.actualRealEstateCode = code;
    },
    uploadImg(file) {
      let self = this;
      return new Promise((resolve, rej) => {
        CloudinaryUploaderForm(file, self.cloudinaryConfig).then(res => {
          const fileCloudinary = res.fileInCloudinary;
          if (Object.keys(fileCloudinary).length > 0) {
            resolve(fileCloudinary.secure_url);
          } else {
            rej(null);
          }
        });
      });
    },
    imgValidate(img) {
      if (typeof img === "object") {
        return true;
      } else {
        return false;
      }
    },
    handleFilesLogo(file) {
      let self = this;
      if (file) {
        this.previewImage(file).then(preview => {
          self.previewLogo = preview;
        });
        CloudinaryUploaderForm(file, self.cloudinaryConfig).then(res => {
          const fileCloudinary = res.fileInCloudinary;
          self.mediaLogo = {
            ...self.mediaLogo,
            ...{
              name: fileCloudinary.original_filename,
              title: fileCloudinary.original_filename,
              src: fileCloudinary.secure_url,
              cloudinary_id: fileCloudinary.public_id
            }
          };
          self.handleUpdateMedia();
        });
      }
    },
    handleFilesMediaFeature(file) {
      let self = this;
      if (file) {
        this.previewImage(file).then(preview => {
          self.previewMediaFeature = preview;
        });
        CloudinaryUploaderForm(file, self.cloudinaryConfig).then(res => {
          const fileCloudinary = res.fileInCloudinary;
          self.previewMediaFeature = fileCloudinary.secure_url;
          self.handleUpdateMedia();
        });
      }
    },

    previewImage(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    handleSubmit() {
      if (!this.activeRequest) {
        if (this.$refs.form.validate()) {
          this.handleUpdateConfig();
        }
      }
    },
    handleCreateRulesPricing(pricing_rules, realEstateID) {
      let self = this;
      this.$snotify.async(
        "Agregando Regla de Precio",
        "Agregando ...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              CreateRulesPricing(pricing_rules, realEstateID)
                .then(rulePrice => {
                  self.real_estate_pricing_rules.push(rulePrice.PricingRules);
                  resolve({
                    title: "Regla de precio",
                    body: `Agregado correctamente`,
                    config: {
                      closeOnClick: true,
                      titleMaxLength: 30,
                      timeout: 4000
                    }
                  });
                })
                .catch(err => {
                  reject({
                    title: "Error!!!",
                    body: `Ocurrio un problema en el proceso. ${err}`,
                    config: {
                      closeOnClick: true,
                      timeout: 4000
                    }
                  });
                })
                .finally();
            }, 500);
          })
      );
    },
    handleUpdateRulesPricing(pricing_rules, pricingRuleId) {
      let self = this;
      this.$snotify.async(
        "Actualizando Regla de Precio",
        "Actualizando ...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              UpdatePricingRule(pricing_rules, pricingRuleId)
                .then(() => {
                  self.real_estate_discount.map(dis => {
                    const idxExistDiscount = pricing_rules.discounts.indexOf(
                      dis["_id"]
                    );
                    if (idxExistDiscount !== -1) {
                      pricing_rules.discounts[idxExistDiscount] = dis;
                    }
                  });
                  Object.assign(
                    self.real_estate_pricing_rules[self.editedIndexPR],
                    pricing_rules
                  );
                  resolve({
                    title: "Regla de precio",
                    body: `Actualizando correctamente`,
                    config: {
                      closeOnClick: true,
                      titleMaxLength: 30,
                      timeout: 4000
                    }
                  });
                })
                .catch(err => {
                  reject({
                    title: "Error!!!",
                    body: `Ocurrio un problema en el proceso. ${err}`,
                    config: {
                      closeOnClick: true,
                      timeout: 4000
                    }
                  });
                })
                .finally();
            }, 500);
          })
      );
    },
    async handleUpdateMiscellaneous() {
      //Subir datos
      //Saber si es un file

      if (this.imgValidate(this.styles_pdf.featured_image)) {
        this.styles_pdf.featured_image = await this.uploadImg(
          this.styles_pdf.featured_image
        );
      }
      if (this.imgValidate(this.styles_pdf.logo)) {
        this.styles_pdf.logo = await this.uploadImg(this.styles_pdf.logo);
      }
      if (this.imgValidate(this.styles_pdf.realEstateGroupLogo)) {
        this.styles_pdf.realEstateGroupLogo = await this.uploadImg(
          this.styles_pdf.realEstateGroupLogo
        );
      }
      if (this.imgValidate(this.styles_pdf.logo_footer)) {
        this.styles_pdf.logo_footer = await this.uploadImg(
          this.styles_pdf.logo_footer
        );
      }

      let self = this;
      self.successMessage = "Miscellaneous Actualizado";
      self.errorMessage = "Error al actualizar Miscellaneous";
      const UpdateMiscellaneous = {
        miscellaneous: {
          website: {
            slug: self.slugWebsite
          },
          aliases: self.aliases,
          mobile_order: self.mobileOrder,
          mapping_coords_structure: self.mappingCoordsStructure,
          main_description: self.maindescrption,
          seo: {
            title: self.seoTitle,
            description: self.seoDescription,
            metas: self.seoMetas,
            schema_script:
              self.schemaScript === "" ? {} : JSON.parse(self.schemaScript)
          },
          quote: {
            active: "on",
            active_two: "on",
            styles_view: self.styles_view,
            styles_pdf: self.styles_pdf,
            include_vat: self.includeIva,
            vat: self.iva
          }
        }
      };
      this.UpdateDevelopment(
        UpdateMiscellaneous,
        this.realEstateID,
        "Actualizando Miscellaneous"
      );
    },
    handleUpdateConfig() {
      let self = this;
      self.successMessage = "Configuracion Actualizado";
      self.errorMessage = "Error al actualizar la Configuracion";
      // self.configApiKeys.push(self.cloudinaryConfig); push

      if (self.configApiKeys.length > 0) {
        self.configApiKeys.map(e => {
          if (e.name === "cloudinary") {
            Object.assign(e, self.cloudinaryConfig);
          }
          return e;
        });
      } else {
        self.configApiKeys.push(self.cloudinaryConfig);
      }

      const UpdateConfig = {
        config: {
          api_keys: self.configApiKeys,
          server: {
            domain: self.configDomain
          }
        }
      };
      self.cloudinaryActive = true;
      self.UpdateDevelopment(
        UpdateConfig,
        this.realEstateID,
        "Actualizando Configuracion Keys"
      );
    },
    handleUpdateMedia() {
      let self = this;
      self.successMessage = "Media Actualizado";
      self.errorMessage = "Error al actualizar la Media";
      self.configApiKeys.push(self.cloudinaryConfig);
      const UpdateMedia = {
        media: {
          featured_image: {
            src: self.previewMediaFeature
          },
          structure: {
            src: self.previewMediaStructure
          },
          logo: self.mediaLogo,
          gallery: self.galleryData
        }
      };
      self.cloudinaryActive = true;
      self.UpdateDevelopment(
        UpdateMedia,
        this.realEstateID,
        "Actualizando Imagenes y Galeria"
      );
    },
    handleCreateDiscount(add_discounts, realEstateID) {
      let self = this;
      this.$snotify.async(
        "Agregando Descuento",
        "Agregando ...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              CreateDiscount(add_discounts, realEstateID)
                .then(addDiscounts => {
                  self.real_estate_discount.push(addDiscounts.Discount);
                  resolve({
                    title: "Descuento",
                    body: `Agregado correctamente`,
                    config: {
                      closeOnClick: true,
                      titleMaxLength: 30,
                      timeout: 4000
                    }
                  });
                })
                .catch(err => {
                  reject({
                    title: "Error!!!",
                    body: `Ocurrio un problema en el proceso. ${err}`,
                    config: {
                      closeOnClick: true,
                      timeout: 4000
                    }
                  });
                })
                .finally();
            }, 500);
          })
      );
    },
    handleUpdateDiscount(add_discounts, discountId) {
      let self = this;
      this.$snotify.async(
        "Actualizando Regla de Precio",
        "Actualizando ...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              UpdateDiscount(add_discounts, discountId)
                .then(() => {
                  Object.assign(
                    self.real_estate_discount[self.editedIndexDis],
                    add_discounts
                  );
                  resolve({
                    title: "Descuento",
                    body: `Actualizado correctamente`,
                    config: {
                      closeOnClick: true,
                      titleMaxLength: 30,
                      timeout: 4000
                    }
                  });
                })
                .catch(err => {
                  reject({
                    title: "Error!!!",
                    body: `Ocurrio un problema en el proceso. ${err}`,
                    config: {
                      closeOnClick: true,
                      timeout: 4000
                    }
                  });
                })
                .finally();
            }, 500);
          })
      );
    },
    saveImage() {
      if (this.editedIndexG > -1) {
        Object.assign(this.galleryData[this.editedIndexG], this.galleryItems);
      } else {
        this.galleryData.push(this.galleryItems);
      }
      this.galleryItems = {
        alt_text: "",
        cloudinary_id: "",
        name: "",
        src: "",
        type: ""
      };
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.seoMetas[this.editedIndex], this.metadata);
      } else {
        this.seoMetas.push(this.metadata);
      }
      this.metadata = {
        property: "",
        content: ""
      };
    },
    addRulePrice() {
      if (this.editedIndexPR > -1 && this.updatePR === true) {
        const rulespricingId = this.real_estate_pricing_rules[
          this.editedIndexPR
        ]["_id"];
        this.handleUpdateRulesPricing(this.pricing_rules, rulespricingId);
        this.updatePR = false;
      } else {
        this.handleCreateRulesPricing(this.pricing_rules, this.realEstateID);
      }
      this.pricing_rules = {
        process_type: "",
        description: "",
        method_payment: "",
        financing: "",
        financing_interest: "",
        percentage_down_payment: "",
        description_percentage_down_payment: "",
        guarantee_deposit: "",
        description_guarantee_deposit: "",
        months_agreed: "",
        montly_payment: "",
        description_method_payment: "",
        termination_payment: "",
        description_termination_payment: "",
        propertys_code: [],
        apply_discount: false,
        number_apply_discount: "0",
        discounts: []
      };
    },
    addDiscounts() {
      if (this.editedIndexDis > -1 && this.updateDis === true) {
        const discountId = this.real_estate_discount[this.editedIndexDis][
          "_id"
        ];
        this.handleUpdateDiscount(this.add_discounts, discountId);
        this.updateDis = false;
      } else {
        this.handleCreateDiscount(this.add_discounts, this.realEstateID);
      }
      this.add_discounts = {
        method_discount: "",
        discount: "",
        months_discount: "",
        description_method_discount: "",
        properties_apply_discount: "",
        condition: "",
        propertys_code: []
      };
    },
    toggle() {
      this.$nextTick(() => {
        if (this.propertyAllSelected) {
          this.pricing_rules.propertys_code = [];
        } else {
          this.pricing_rules.propertys_code = this.listPropertyCode.map(
            property => property.name
          );
        }
      });
    },
    limiter(e) {
      let number = this.pricing_rules.number_apply_discount;
      number = number ? number : 0;
      if (e.length > number) {
        e.pop();
      }
    },
    deleteItem(item) {
      const index = this.seoMetas.indexOf(item);
      confirm("¿Seguro que quieres eliminar este meta?") &&
        this.seoMetas.splice(index, 1);
    },
    editItem(item) {
      this.editedIndex = this.seoMetas.indexOf(item);
      this.metadata = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItemPR(item) {
      const index = this.real_estate_pricing_rules.indexOf(item);
      confirm("¿Seguro que quieres eliminar este meta?") &&
        this.real_estate_pricing_rules.splice(index, 1);
    },
    editItemPR(item) {
      this.editedIndexPR = this.real_estate_pricing_rules.indexOf(item);
      this.pricing_rules = Object.assign({}, item);
      this.pricing_rules.discounts = item.discounts.map(
        discount => discount["_id"]
      );
      this.updatePR = true;
    },
    editItemDis(item) {
      this.editedIndexDis = this.real_estate_discount.indexOf(item);
      this.add_discounts = Object.assign({}, item);
      this.updateDis = true;
    },
    deleteItemGallery(item) {
      const index = this.galleryData.indexOf(item);
      const bool = confirm("¿Seguro que quieres eliminar esta imagen?");
      if (bool) {
        this.galleryData.splice(index, 1);
        this.handleUpdateMedia();
      }
    },
    editItemGallery(item) {
      this.editedIndexG = this.galleryData.indexOf(item);
      this.galleryItems = Object.assign({}, item);
      this.dialog = true;
    },
    UpdateDevelopment(dataUpdate, realEstateID, message) {
      // const successMessage = "Actualizado correctamente";
      // const errorMessage = "Error";
      let self = this;
      self.loading = true;
      self.activeRequest = true;
      this.$snotify.async(
        message,
        "Procesando ...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              UpdateRealEstateDevelopment(dataUpdate, realEstateID)
                .then(rulePrice => {
                  self.real_estate_pricing_rules.push(rulePrice.PricingRules);
                  resolve({
                    title: `${self.successMessage}`,
                    body: `Proceso ejecutado correctamente`,
                    config: {
                      closeOnClick: true,
                      titleMaxLength: 30,
                      timeout: 4000
                    }
                  });
                })
                .catch(err => {
                  reject({
                    title: `${self.errorMessage}!!!`,
                    body: `Ocurrio un problema en el proceso. ${err}`,
                    config: {
                      closeOnClick: true,
                      timeout: 4000
                    }
                  });
                })
                .finally(() => {
                  self.activeRequest = false;
                  self.loading = false;
                  // self.clearForm();
                });
            }, 500);
          })
      );
    },
    clearForm() {
      this.$refs.form.reset();
      this.isActiveRequest = false;
      this.responseMessage = "";
      this.serverError = "";
    },
    getDiscountAssigned(discount) {
      const listDiscount = [];
      discount.forEach(rDiscount => {
        listDiscount.push(`${rDiscount.discount}% `);
      });
      return listDiscount.toString();
    },
    printDiscountValue(discount, type_discount) {
      if (discount) {
        return type_discount === "percentage" ? `${discount}%` : `$${discount}`;
      }
      return "-";
    },
    changeActiveDiscount(valor) {
      if (valor) {
        this.pricing_rules.number_apply_discount = 1;
      } else {
        this.pricing_rules.number_apply_discount = 0;
        this.pricing_rules.discounts = [];
      }
    }
  },
  async created() {
    this.realEstateCode = this.$route.params.code;
    await this.consultDetails();
    await this.consultProperties();
    await this.consultDiscount();
    await this.consultPricingRules();
  },
  computed: {
    logFileText() {
      return this.mediaLogo.name != "" ? "Logo Actual" : "Vista Previa";
    },
    formTitle() {
      return this.editedIndexG === -1 ? "Agregar" : "Editar ";
    },
    formTitleQuote() {
      return this.editedIndexG === -1 ? "Guardar" : "Actualizar ";
    },
    formTitlePR() {
      return this.editedIndexPR === -1 || this.updatePR === false
        ? "Guardar"
        : "Actualizar ";
    },
    formTitleDis() {
      return this.editedIndexDis === -1 || this.updateDis === false
        ? "Guardar"
        : "Actualizar ";
    },
    mpDepositLabel() {
      return this.pricing_rules.process_type == "rent"
        ? "Deposito"
        : "Apartado";
    },
    propertyAllSelected() {
      return (
        this.pricing_rules.propertys_code.length ===
        this.listPropertyCode.length
      );
    },
    propertySelected() {
      return (
        this.pricing_rules.propertys_code.length > 0 &&
        !this.propertyAllSelected
      );
    },
    icon() {
      if (this.propertyAllSelected) return "mdi-close-box";
      if (this.propertySelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    ...mapState({
      contactsListLoadingSt: state => state.contacts.ContactsListLoading,
      contactListSt: state => state.contacts.contactList
    })
  }
};
</script>

<style lang="scss" scoped>
.center-item {
  margin-left: auto;
  margin-right: auto;
}
.top-50 {
  top: 60%;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  left: 0;
}
.size-table {
  width: 33vw;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent !important;
}
.theme--light.v-data-table {
  background-color: transparent !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: none !important;
}
.disabled-button {
  cursor: none;
}
</style>
