import { background, title, colors } from "./style";
import { word } from "./multi-language";
let doc;
let colorsA = {};
let language = "spanish";
export function officeDetail(document, dataDetail, lang) {
  if (lang) language = lang;
  colorsA = colors();
  doc = document;
  doc.addPage();
  background(doc);

  title(doc, `${word(66, language)}`, 5, 10); //66
  title(doc, `${word(67, language)}`, 5, 50); //67
  formDetail(dataDetail);
}

function formDetail(fields) {
  doc.setDrawColor("#D5D8DC");
  doc.setLineWidth(0.5);

  let init_x = 7;
  const init_y = 15;
  const increment = 5;
  const size_line = 100; //tamaño fijo..
  let init_line = 220; //check */

  let position_y = init_y;
  fields.forEach(e => {
    text_colum(e.name, init_x, position_y);
    line_colum(init_line, position_y, size_line);
    text_result(e.result, init_line, position_y);
    position_y += increment;
    //Acomodar en la siguiente fila
    if (position_y > 40 && init_x == 7) {
      position_y = init_y;
      init_x = 50;
      init_line = 505;
    }
  });
}
function item_text(text, x, y) {
  doc.setTextColor(colorsA.text_color);
  doc.text(text, x * 6.773, y * 3.81);
}
function text_colum(text, x, y) {
  doc.setFontSize(23);
  item_text(`${text}  :`, x, y);
}
function line_colum(x, y, size) {
  doc.setDrawColor(colorsA.text_color);
  doc.line(x, y * 3.81, x + size, y * 3.81);
}
function text_result(text, x, y) {
  text = `${text}`;
  doc.setFontSize(19);
  doc.text(text, x + 5, y * 3.81 - 5);
}
