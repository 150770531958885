<template>
  <div>
    <v-row v-if="loading" align="center" justify="center">
      <v-col align-self="center" cols="12">
        <div class="text-center">
          <v-progress-circular
            :width="6"
            :size="150"
            color="primary"
            indeterminate
            style="margin:90px; left: 0; z-index: 2; border: none;"
            >Cargando...</v-progress-circular
          >
        </div>
      </v-col>
    </v-row>
    <div v-else></div>
    <div v-show="!loading">
      <iframe
        id="main-iframe"
        style="width: 100%; height: 75vh; position: static; z-index: 2; border: none;"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { quotePreview } from "@/pdf/advanced-quoter/main.js";
export default {
  props: {
    hideCurrency: {
      String: String,
      default: false
    },
    defaultActive: {
      Boolean: Boolean,
      default: false
    },
    logoDesing: {
      String: String,
      default: ""
    },
    imgEstateDesing: {
      String: String,
      default: ""
    },
    realEstateGroupLogoDesing: {
      String: String,
      default: ""
    },
    //new logo footer
    logoFooter: {
      String: String,
      default: ""
    },
    bgOne: {
      String: String,
      default: "#fff"
    },
    bgTwo: {
      String: String,
      default: "#000"
    },
    textColor: {
      String: String,
      default: "#000"
    },
    titleColor: {
      String: String,
      default: "#000"
    },
    titleTwoColor: {
      String: String,
      default: "#000"
    },
    textThreeColor: {
      String: String,
      default: "#000"
    },
    imgEstate: {
      String: String,
      default: ""
    },
    propertyData: {
      type: Array,
      default: () => []
    },
    unitTypes: {
      type: String,
      default: "offices"
    }
  },
  data() {
    return {
      default: false,
      srcLogo: this.logoEstae,
      tab: null,
      currentColor: this.bgOne,
      loading: true
    };
  },
  watch: {
    hideCurrency: function() {
      this.update();
    },
    logoDesing: function() {
      this.update();
    },
    imgEstateDesing: function() {
      this.update();
    },
    realEstateGroupLogoDesing: function() {
      this.update();
    },
    logoFooter: function() {
      this.update();
    },
    bgOne: function() {
      this.update();
    },
    bgTwo: function() {
      this.update();
    },
    textColor: function() {
      if (!this.defaultActive) {
        this.loading = true;
        setTimeout(() => {
          this.refresh();
        }, 500);
        setTimeout(() => {
          this.refresh();
          this.loading = false;
        }, 1200);
      }
    },
    titleColor: function() {
      this.update();
    },
    titleTwoColor: function() {
      this.update();
    },
    textThreeColor: function() {
      this.update();
    },
    defaultActive: function() {
      if (!this.defaultActive) {
        this.refresh();
      }
      this.loading = !this.loading;
    }
  },
  computed: {
    logoPreview() {
      return this.srcLogo;
    },
    logoDesingInternal() {
      return this.imgValidate(this.logoDesing);
    },
    imgEstateDesingInternal() {
      return this.imgValidate(this.imgEstateDesing);
    },
    realEstateGroupLogoDesingInternal() {
      return this.imgValidate(this.realEstateGroupLogoDesing);
    },
    logoFooterInternal() {
      return this.imgValidate(this.logoFooter);
    }
  },

  /* eslint-disable */
  mounted() {
    setTimeout(() => {
      PDFObject.embed(this.createPDF(), "#previewPDF");
      this.loading = false;
    }, 1200);
  },
  methods: {
    update() {
      if (!this.defaultActive) {
        this.loading = true;
        setTimeout(() => {
          this.refresh();
        }, 500);
        setTimeout(() => {
          this.loading = false;
        }, 1200);
      }
    },
    imgValidate(data) {
      if (data !== null) {
        if (typeof data !== "string") {
          return URL.createObjectURL(data);
        } else {
          return data;
        }
      } else {
        return data;
      }
    },
    refresh() {
      if (!this.defaultActive) {
        this.createPDF();
      }
    },

    createPDF() {
      const dataFrontPage = {
        address: "Av. Lorem Ipsum 123 lote 10, 77710.",
        nameDev: "Zenica",
        nameLead: "Nombre del Lead"
      };
      const structure = {
        style: this.styleData(),
        dataFrontPage,
        contactSelected: this.contactSeletedData(),
        properties: this.propertiesData(),
        packedPriceRule: this.packedPriceRuleData(),
        purchaseScheme: this.purchaseSchemeData()
      };
      //const unit_types = "lots";
      const doc = quotePreview(structure, this.unitTypes);
      document
        .getElementById("main-iframe")
        .setAttribute("src", doc.output("bloburl"));
    },
    styleData() {
      return {
        featured_image: this.imgEstateDesingInternal,
        hideCurrency: false,
        logo: this.logoDesingInternal,
        logo_footer: this.logoFooterInternal,
        container_one: this.bgOne,
        container_two: this.bgTwo,
        container_separator: "#636565",
        page_background: "#283042",
        text_color: this.textColor,
        title_color: this.titleColor,
        title2_color: this.titleTwoColor,
        text3_color: this.textThreeColor
      };
    },
    contactSeletedData() {
      return {
        created_at: "2022-04-06T19:42:06.966Z",
        email: "info@capital28.investments",
        exam_passed: null,
        media: {
          featured_image: {
            cloudinary_id:
              "Capital%2028/brokers/Gilberto Bolanos Cacho Martinez",
            src: "",
            src_avatar: ""
          }
        },
        name: "Nombre del Asesor",
        performance: {
          active: 0,
          defeated: 12,
          total: 12,
          totalLeads: 12
        },
        phone: "+52 998 567 8903",
        player_id: "0",
        real_estate_develop_id: null,
        real_estate_group_id: "610412d0707aaaa569a2000a",
        role: "admin",
        status: 1,
        type: "internal",
        updated_at: "2022-04-06T19:42:06.966Z",
        user_id: "624ded0e69818b386c9fa5a4",
        _id: "624ded0e69818bb5539fa5a2"
      };
    },
    propertiesData() {
      return [
        {
          amenities: [
            {
              abbreviation: "N2-B4",
              code: "B4",
              construction: {
                total: 12.89,
                unit: "m2"
              },
              contract_status: "indefinido",
              created_at: "2022-07-19T17:30:51.661Z",
              floor: {
                code: "N2",
                name: "Nivel 2"
              },
              gallery: [],
              media: null,
              miscellaneous: null,
              name: "Bodega",
              pricing: Object,
              real_estate_development: null,
              real_estate_group_id: "610412d0707aaaa569a2000a",
              status: 1,
              updated_at: "2022-07-19T17:30:51.661Z",
              __v: 0,
              _id: "62d6ea4bb46166b2620fa073"
            },
            {
              abbreviation: "S2-C35",
              code: "C35",
              construction: {
                unit: ""
              },
              contract_status: "indefinido",
              created_at: "2022-07-19T17:30:51.668Z",
              floor: {
                code: "S2",
                name: "Sótano 2"
              },
              gallery: [],
              media: null,
              miscellaneous: null,
              name: "Cajon",
              pricing: null,
              real_estate_development: null,
              real_estate_group_id: "610412d0707aaaa569a2000a",
              status: 1,
              updated_at: "2022-07-19T17:30:51.668Z",
              __v: 0,
              _id: "62d6ea4bb461664c950fa07a"
            }
          ],
          building: {
            construction: 59.74,
            terrace: "8.58",
            total: 600.12,
            type: "Tipo de propiedad"
          },
          code: "CODIGO DE PROPIEDAD",
          contract_status: "disponible",
          desarrollo: null,
          features: null,
          floor: {
            code: "N6",
            name: "Nivel 6"
          },
          media: {
            featured_image: {
              name: "",
              title: "",
              src:
                "https://res.cloudinary.com/di6cg0ywy/image/upload/v1653599945/arrecife/lote_10_cbzpfi.png",
              cloudinary_id: ""
            },
            plane_image: {
              name: "",
              title: "",
              src:
                "https://res.cloudinary.com/di6cg0ywy/image/upload/v1653599945/arrecife/lote_10_cbzpfi.png",
              cloudinary_id: ""
            }
          },
          miscellaneous: null,
          pricing: {
            currency: "MXN",
            price: 3354049.51
          },
          pricing_rules: [],
          real_estate_development: null,
          type: "(Tipo)",
          __v: 0,
          _id: "62d6ea48b4616632c70f9ea8"
        }
      ];
    },
    packedPriceRuleData() {
      return {
        currency: "MXN",
        finalPrice: 2346706.6569999997,
        finalPriceSIva: 2080413.7030141843,
        initPrice: 3354049.51,
        initPriceSIva: 2973448.14716312,
        iva: 12.8,
        nameDev: "Zenica",
        priceRuleApplied: {
          discounts: [],
          extraDiscounts: [],
          numberApplyDiscount: 4,
          priceRules: [
            {
              percent: "MXN $5,000",
              text: "Apartado"
            },
            {
              percent: "25%",
              percentValue: 25,
              text: "Enganche"
            },
            {
              percent: "65%",
              percentValue: 65,
              text: "Financiamiento"
            },
            {
              percent: "10%",
              percentValue: 10,
              text: "Liquidación"
            }
          ],
          priceRulesDiscount: [
            {
              percent: "10%",
              text: "DTCO 10% PREVENTA",
              type: "percentage",
              value: 10,
              _id: "62be4fafed1393669425e6e5"
            },
            {
              percent: "20%",
              text: "DTCO 20% ",
              type: "percentage",
              value: 20,
              _id: "62c468a502b0366ca2f4dfd1"
            },
            {
              percent: "MXN $1,000",
              text: "Off 1000",
              type: "fixed",
              value: 1000,
              _id: "62c746ad4d66cd33c89730de"
            }
          ],

          totalDiscount: 893034.444148936
        },
        seletedPriceRules: {
          apply_discount: true,
          created_at: "2022-07-01T01:46:28.806Z",
          description: "Plan de Pagos",
          description_guarantee_deposit: "APARTADO",
          description_method_payment: "FINANCIADO A MESES DE OBRA",
          description_percentage_down_payment: "ENGANCHE ",
          description_termination_payment: "CAMINO A LA ESCRITURA",
          discounts: [],
          financing: 65,
          guarantee_deposit: 5000,
          method_payment: "financing",
          months_agreed: 21,
          number_apply_discount: 4,
          percentage_down_payment: 25,
          process_type: "pre-sale",
          propertys_code: [],
          real_estate_developments_id: "61041565707aaa5403a20388",
          real_estate_group_id: "610412d0707aaaa569a2000a",
          status: 1,
          termination_payment: 10,
          updated_at: "2022-07-11T19:29:06.373Z"
        },
        totalDiscounts: 893034.444148936,
        totalMeters: 81.21000000000001
      };
    },
    purchaseSchemeData() {
      return [
        {
          amount: "MXN $5,000",
          description: "APARTADO",
          percent: "0.21%",
          text: "Apartado"
        },
        {
          amount: "MXN $581,677",
          description: "ENGANCHE ",
          percent: "24.79%",
          text: "Enganche"
        },
        {
          amount: "MXN $1,525,359",
          description: "FINANCIADO A MESES DE OBRA",
          percent: "65%",
          text: "Financiamiento"
        },
        {
          amount: "MXN $72,636",
          description: "",
          percent: 21,
          text: "> Mensualidades"
        },
        {
          amount: "MXN $234,671",
          description: "CAMINO A LA ESCRITURA",
          percent: "10%",
          text: "Liquidación"
        },
        {
          amount: "MXN $2,346,707",
          description: this.unitTypes == "lots" ? "" : "IVA 12.8%",
          percent: "100%",
          text: "PRECIO FINAL"
        }
      ];
    }
  }
};
</script>
<style>
.pdfobject-container {
  height: 30rem;
  border: 1rem solid rgba(0, 0, 0, 0.1);
}
</style>
