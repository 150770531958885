import autoTable from "jspdf-autotable";
import { word } from "./multi-language";
import { background, title, botttomLogo, colors, textFont } from "./style";
let language = "spanish";
let colorsA = {};
export function packedPriceRuleTable(doc, data) {
  const process_type =
    data.seletedPriceRules.process_type == "pre-sale" ? "Preventa" : "Venta";
  const presale = [
    {
      text: `${word(51, language)} ${process_type}`,
      percent: `${data.currency} ${currency(data.initPriceSIva)}`,
    },
    {},
  ];

  const totalDiscounts = [];

  if (data.totalDiscounts) {
    totalDiscounts.push({
      text: `${word(47, language)} ${data.currency}`,
      percent: `${data.currency} ${currency(data.totalDiscounts)}`,
    });
  }
  const priceRules = [
    ...presale,
    ...data.priceRuleApplied.priceRules,
    ...[{}],
    ...data.priceRuleApplied.priceRulesDiscount,
    ...data.priceRuleApplied.extraDiscounts,
    ...totalDiscounts,
  ];
  doc.addPage();
  background(doc);

  offerTable(doc, data);

  //Agregar el nombre de la inmobiliaria..
  const dev = data.nameDev.toUpperCase();
  title(doc, `${word(48, language)} : ${dev} 2022`, 5, 35);
  autoTable(doc, {
    startY: 150,
    theme: "plain",
    columnStyles: { text: { halign: "center" }, percent: { halign: "center" } },
    body: priceRules,
    columns: [{ dataKey: "text" }, { dataKey: "percent" }],
    styles: { fontSize: 28, textColor: "#F2F3F4" },
  });

  botttomLogo(doc);
}

export function purchaseSchemeTable(doc, data) {
  doc.addPage();
  background(doc);
  const finalPrice = word(36, language);
  const head = data.filter((item) => item.text == finalPrice);
  const body = data.filter((item) => item.text != finalPrice);
  autoTable(doc, {
    theme: "plain",
    startY: 100,
    columnStyles: {
      text: { halign: "center" },
      percent: { halign: "center" },
      description: { halign: "center" },
    },
    head: head,
    body: body,
    columns: [
      { dataKey: "text" },
      { dataKey: "percent" },
      { dataKey: "description" },
      { dataKey: "amount" },
    ],
    styles: {
      fontSize: 28,
      textColor: "#F2F3F4",
      halign: "center",
      cellPadding: 3,
    },
    headStyles: {
      textColor: "#1C2833",
      fillColor: "#F2F3F4",
    },
  });

  botttomLogo(doc);
}

function offerTable(doc, data) {
  const process_type =
    data.seletedPriceRules.process_type == "pre-sale"
      ? word(64, language)
      : word(65, language);
  title(doc, `${process_type.toUpperCase()}`, 5, 10);
  const offer = [
    {
      one: `${word(53, language)}`,
      two: `${data.currency} ${currency(data.initPriceSIva)}`,
      three: `${word(54, language)}`,
      four: `${data.currency} ${currency(data.initPrice)}`,
    },
    {
      one: `${word(55, language)}`,
      two: `${data.currency} ${currency(
        data.initPriceSIva / data.totalMeters
      )}`,
      three: "Total m²",
      four: `${data.totalMeters.toFixed(2)} m²`,
    },
  ];
  autoTable(doc, {
    theme: "plain",
    startY: 50,
    body: offer,
    columns: [
      { dataKey: "one" },
      { dataKey: "two" },
      { dataKey: "three" },
      { dataKey: "four" },
    ],
    margin: { left: 50, right: 50, top: 100 },
    styles: {
      fontSize: 28,
      textColor: colorsA.text_color,

      cellPadding: 3,
    },
  });
}

const currency = function(number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(number);
};
// aqui se hace la tabla
export function mergeSchemeTable(
  doc,
  packedRule,
  schema,
  workWithoutVAT,
  lang,
  dataFront
) {
  if (lang) language = lang;
  colorsA = colors();
  let startYmergeTable = 80;
  //const dev = packedRule.nameDev.toUpperCase();
  let nameScheme = "";

  if (
    packedRule.seletedPriceRules &&
    packedRule.seletedPriceRules.description
  ) {
    nameScheme = packedRule.seletedPriceRules.description.toUpperCase();
  }

  doc.addPage();
  background(doc);
  if (!workWithoutVAT) {
    offerTable(doc, packedRule);
    title(doc, `${word(6, language)}: ${nameScheme}`, 5, 35);
    startYmergeTable = 150;
  } else {
    title(doc, `${word(6, language)}: ${nameScheme}`, 5, 10);
  }
  const finalPrice = word(36, language);
  const head = schema.filter((item) => item.text == finalPrice);
  const months = schema.filter(
    (item) => item.text === `# ${word(45, language)}`
  );
  const body = schema.filter(
    (item) => item.text != finalPrice && item.text !== `# ${word(45, language)}`
  );

  const schemaFormat = [...head, ...[{}], ...body, ...[{}], ...months];

  const dataTable = [...discounts(packedRule), ...schemaFormat];

  const positionFinalPrice = dataTable.findIndex(
    (item) => item.text == finalPrice
  );
  autoTable(doc, {
    startY: startYmergeTable,
    theme: "plain",
    columns: [
      { dataKey: "text" },
      { dataKey: "percent" },
      { dataKey: "description" },
      { dataKey: "amount" },
    ],
    body: dataTable,
    styles: { fontSize: 28, textColor: colorsA.text_color },
    //bodyStyles: { lineColor: "#F2F3F4" },
    margin: { left: 50, right: 50, top: 100 },
    didParseCell: (data) => {
      //Desarrollar funcion para poder cambiar de color en diferentes filas..

      if (data.row.index == positionFinalPrice) {
        data.cell.styles.textColor = colorsA.title_color;
        data.cell.styles.fillColor = `${colorsA.container_two}`;
      }
    },
  });
  if (doc.lastAutoTable.finalY < 345) {
    botttomLogo(doc,dataFront?.nameDev?.toLowerCase() ?? "");
  }
  const developmentName = dataFront?.nameDev?.toLowerCase() ?? "";

  if (developmentName === "ikonic tower") {
    textFont(doc, 25, `${word(90, language)}`, 8, 74, "", "#ffffff", true);
    textFont(doc, 25, `${word(91, language)}`, 62, 74, "", "#ffffff", true);
  }

  //Datos mergeados de packedPriceeRuleTable y PurchaseSchemeTable..
}
function discounts(data) {
  const process_type =
    data.seletedPriceRules.process_type == "pre-sale"
      ? word(62, language)
      : word(63, language);
  const presale = [
    {
      text: `${process_type}`,
      percent: "",
      description: "",
      amount: `${data.currency} ${currency(data.initPriceSIva)}`,
    },
    {},
  ];

  let totalDiscounts = [];
  if (data.totalDiscounts) {
    totalDiscounts = [
      {
        text: `${word(47, language)} ${data.currency}`,
        percent: "",
        description: "",
        amount: `${data.currency} ${currency(data.totalDiscounts)}`,
      },
      {},
    ];
  }
  const priceRules = [
    ...data.priceRuleApplied.priceRulesDiscount,
    ...data.priceRuleApplied.extraDiscounts,
  ];

  let format = [];
  priceRules.forEach((a) => {
    const dts = {
      text: a.text,
      percent: "",
      description: "",
      amount: a.percent,
    };
    format.push(dts);
  });

  return [...presale, ...format, ...totalDiscounts];
}
