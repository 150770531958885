import functions from "@/pdf/qoute-generate-pdf";

function generatePdf() {
  functions.start();
  functions.text(50, "hola", 20, 15, "bold");
  functions.text(50, "hola", 20, 40);
  functions.text(50, "hola", 20, 60, "light");
}
function portada(
  name,
  asesor,
  lenguage,
  media,
  contactDeveloment,
  addressDeveloment,
  mediaDeveloment,
  stylePdfDevelopment
) {
  let emailDeveloment,
    phoneDeveloment,
    fulladdresDeveloment,
    styleTitle,
    styleName,
    styleNameClient,
    styleEmail,
    stylePhone,
    styleAddress;
  styleTitle = setParamsValue(
    "title_color" in stylePdfDevelopment,
    stylePdfDevelopment["title_color"],
    "#ffffff"
  );
  styleName = setParamsValue(
    "text3_color" in stylePdfDevelopment,
    stylePdfDevelopment["text3_color"],
    "#ffffff"
  );
  styleNameClient = setParamsValue(
    "title2_color" in stylePdfDevelopment,
    stylePdfDevelopment["title2_color"],
    "#ffffff"
  );
  styleEmail = setParamsValue(
    "text_color" in stylePdfDevelopment,
    stylePdfDevelopment["text_color"],
    "#ffffff"
  );
  stylePhone = setParamsValue(
    "text_color" in stylePdfDevelopment,
    stylePdfDevelopment["text_color"],
    "#ffffff"
  );
  styleAddress = setParamsValue(
    "text_color" in stylePdfDevelopment,
    stylePdfDevelopment["text_color"],
    "#ffffff"
  );

  emailDeveloment = setParamsValue(
    "email" in contactDeveloment,
    contactDeveloment.email,
    "Sin Correo"
  );
  phoneDeveloment = setParamsValue(
    "phone" in contactDeveloment,
    contactDeveloment.phone,
    "Sin Telefono"
  );
  fulladdresDeveloment = setParamsValue(
    "full_address" in addressDeveloment,
    addressDeveloment.full_address,
    "Sin direccion"
  );

  functions.addLogoDevelopment(mediaDeveloment);
  functions.addFeaturedImageDevelopment(mediaDeveloment);
  functions.addGalleryDevelopment(mediaDeveloment);
  functions.getColorPDF(stylePdfDevelopment);
  functions.addProfileImage(media);
  functions.start();
  functions.graphicsFront();
  functions.text(
    35,
    lenguage == "Español" ? "PROPUESTA" : "PROPOSAL",
    20,
    10,
    "bold",
    styleTitle
  );
  functions.text(35, name, 20, 15, "", styleNameClient);

  let cell = `${phoneDeveloment}`;
  cell = cell.replace(/\s+/g, "");
  cell = cell.replace("+52", "");
  if (cell.length === 10) {
    cell =
      "+52 " +
      cell[0] +
      cell[1] +
      cell[2] +
      " " +
      cell[3] +
      cell[4] +
      cell[5] +
      " " +
      cell[6] +
      cell[7] +
      cell[8] +
      cell[9];
  }

  functions.text(
    25,
    (lenguage == "Español" ? "Teléfono Asesor: " : "Broker Phone: ") + cell,
    70,
    54,
    2,
    stylePhone
  );

  functions.text(25, `${emailDeveloment}`, 70, 58, 2, styleEmail);

  //Función para acomodar la dirección
  formattedAddress(`${fulladdresDeveloment}`, 36, styleAddress);

  const temretu = functions.agent(asesor, 470, 110, lenguage, styleName); //Retornar lo que del pdf para probarlo temporalmente
  return temretu;
  // functions.photoShow(); //descomentar cuando se arregle la galeria
}
function formattedAddress(text, rowSize, styleAddress) {
  const arr = text.split(" ");
  let count = -1;
  let rows = [];
  let tempRow = "";
  for (let i = 0; i < arr.length; i++) {
    count = count + arr[i].length + 1;
    if (count < rowSize) {
      tempRow += arr[i] + " ";
    } else {
      count = arr[i].length + 1;
      rows.push(tempRow);
      tempRow = arr[i] + " ";
    }
  }
  if (tempRow !== "") {
    rows.push(tempRow);
  }
  let y = 62;
  rows.forEach(rw => {
    functions.text(25, rw, 70, y, 2, styleAddress);
    y += 4;
  });
}
function departamentos(
  //Opcion de retornar los datos o crear el pdf para descargar
  dep,
  asesor,
  lenguage,
  datos,
  moneda,
  tipoCambio,
  ifchange,
  includeIva,
  IVA,
  realEstateGroupLogo,
  hideCurrency
) {
  /* Adentro de blueprints se recorren las amenidades.. */
  functions.blueprints(dep, asesor, lenguage);

  /*   
  Se trabaja en esta sección
  functions.amenities(dep, asesor, lenguage); 
  En ves de dep van a venir las amenidades...
  
  */
  //functions.amenities(dep, asesor, lenguage);
  functions.EconomicProposal(
    dep,
    lenguage,
    datos,
    moneda,
    tipoCambio,
    ifchange,
    includeIva,
    IVA,
    hideCurrency
  );
  if (realEstateGroupLogo) {
    return functions.end(realEstateGroupLogo);
  } else {
    return functions.end();
  }
}

function setParamsValue(condition, value, def) {
  if (condition) {
    return value;
  } else {
    return def;
  }
}

export default {
  generatePdf,
  portada,
  departamentos
};
