<template>
  <div>
    <v-container fluid>
      <v-row align="center" justify="center">
        <!-- <pre>{{ developmentInformation }}</pre> -->
        <v-col cols="12" md="12" class="" align-self="start">
          <h3 class="text-center">Imagen destacada del desarrollo</h3>
          <br />
          <div class="text-center">
            <v-text-field
              :disabled="disabledEdit()"
              append-icon="mdi-delete"
              @click:append="previewMediaFeature = ''"
              :loading="loading"
              v-model="previewMediaFeature"
            ></v-text-field>
          </div>
          <v-img
            :src="
              !previewMediaFeature ||
              previewMediaFeature == '' ||
              previewMediaFeature == null
                ? '/img/image-not-available.png'
                : previewMediaFeature
            "
            height="350px"
            contain
          >
            <v-row
              align="center"
              justify="center"
              class="fill-height"
              align-content="end"
            >
              <!-- <v-btn
                v-show="
                  !previewMediaFeature ||
                    previewMediaFeature == '' ||
                    previewMediaFeature == null
                "
                @click="openP"
                >Ir a galeria</v-btn
              > -->
            </v-row>
          </v-img>
        </v-col>

        <v-col cols="12" md="12" class="" align-self="start">
          <!-- <pre>{{ developmentInformation }}</pre> -->
          <v-row align="center" justify="center">
            <v-col cols="12" md="5" align-self="start" class="">
              <h3 class="text-center my-4">Dirección</h3>
              <v-text-field
                :disabled="disabledEdit()"
                label="Ciudad"
                v-model="city_address"
                :loading="loading"
              ></v-text-field>
              <v-text-field
                :disabled="disabledEdit()"
                label="Estado"
                v-model="state_address"
                :loading="loading"
              ></v-text-field>
              <v-text-field
                :disabled="disabledEdit()"
                label="Dirección"
                v-model="fullAddress"
                :loading="loading"
              ></v-text-field>
              <br />

              <h3 class="text-center my-5">Estatus del desarrollo</h3>

              <v-select
                :disabled="disabledEdit()"
                :items="statusDevelopment"
                v-model="actualStatus"
                label="Estatus Actual"
                dense
                :loading="loading"
              ></v-select>

              <v-row align="center" justify="center">
                <v-col>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dateTemp"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="disabledEdit()"
                        v-model="date_delivery"
                        label="Fecha de entrega estimada"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date_delivery" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="disabledEdit()"
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        Cerrar
                      </v-btn>
                      <v-btn
                        :disabled="disabledEdit()"
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        Ok
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <!--   <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Fecha de entrega estimada"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu> -->
                </v-col>
              </v-row>

              <br />
            </v-col>
            <v-col cols="12" md="5" class="" align-self="start">
              <h3 class="text-center my-4">Datos de contacto</h3>

              <v-text-field
                :disabled="disabledEdit()"
                label="Teléfono"
                prepend-icon="mdi-phone"
                v-model="phoneEstate"
                :loading="loading"
              ></v-text-field>
              <v-text-field
                :disabled="disabledEdit()"
                label="Correo electrónico"
                prepend-icon="mdi-email"
                v-model="emailEstate"
                :loading="loading"
              ></v-text-field>
              <v-text-field
                :disabled="disabledEdit()"
                label="Página web"
                prepend-icon="mdi-web"
                v-model="linkWeb"
                :loading="loading"
              ></v-text-field>

              <br />
              <h3 class="text-center my-4">Social media</h3>

              <v-text-field
                :disabled="disabledEdit()"
                label="Link facebook"
                prepend-icon="mdi-facebook"
                v-model="facebookEstate"
                :loading="loading"
              ></v-text-field>
              <v-text-field
                :disabled="disabledEdit()"
                label="Link instagram"
                prepend-icon="mdi-instagram"
                v-model="instagramEstate"
                :loading="loading"
              ></v-text-field>

              <br />
            </v-col>

            <v-col cols="6" class="">
              <h3 class="text-center my-4">Material Desarrollo</h3>

              <v-text-field
                :disabled="disabledEdit()"
                label="Link de google drive"
                prepend-icon="mdi-google-drive"
                v-model="driveLink"
                :loading="loading"
              ></v-text-field>
              <v-text-field
                :disabled="disabledEdit()"
                label="Link del brochure"
                prepend-icon="mdi-file-pdf-box"
                v-model="brochureLink"
                :loading="loading"
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="12" md="3" class="blue"></v-col> -->
          </v-row>
        </v-col>

        <div class="text-center">
          <v-btn
            :disabled="disabledEdit()"
            color="success"
            :loading="loading"
            @click="updateAll()"
            >Guardar</v-btn
          >
        </div>
      </v-row>

      <!-- <pre>{{ computedDateFormattedMomentjs }}</pre> -->
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("es");
import { UpdateRealEstateDevelopment } from "@/api/real-estate-development-api.js";
export default {
  props: {
    developmentInformation: {
      type: Object
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    //Datos antiguos
    dateTemp: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    openP: false,
    date: "",
    loading: false,
    activeRequest: false,
    successMessage: "",
    errorMessage: "",
    valid: false,
    // dev info
    previewMediaFeature: "",
    previewMediaStructure: "",
    developmentID: "",
    statusDevelopment: ["Terminado", "Inversión", "En Obra", "Indefinido"],
    //statusDevelopment: ["construction","investment","finished"],
    actualStatus: "",
    date_delivery: "",
    city_address: "",
    state_address: "",
    fullAddress: "",
    phoneEstate: "",
    emailEstate: "",
    linkWeb: "",
    facebookEstate: "",
    instagramEstate: "",
    brochureLink: "",
    driveLink: "",
    start_date: "",
    role: localStorage.getItem("user_role")
    //upload infi
  }),
  mounted() {
    this.fetchInformation();
  },

  computed: {
    computedDateFormattedMomentjs() {
      return this.date_delivery
        ? moment(this.date_delivery).format("MMMM YYYY")
        : "";
    }
  },
  methods: {
    disabledEdit() {
      if (this.role === null) return true;
      if (this.role === "coordinator") return true;
      if (this.role === "admin") return false;
      return true;
    },
    fetchInformation() {
      setTimeout(() => {
        this.structureData(this.developmentInformation);
      }, 50);
    },

    getStatus(status) {
      if (status === "investment") {
        return "Inversión";
      }
      if (status === "construction") {
        return "En Obra";
      }
      if (status === "finished") {
        return "Terminado";
      }
      if (status === "") {
        return "Indefinido";
      }
    },

    setStatus(status) {
      if (status === "Inversión") {
        return "investment";
      }
      if (status === "En Obra") {
        return "construction";
      }
      if (status === "Terminado") {
        return "finished";
      }
      if (status === "Indefinido") {
        return "";
      }
    },
    structureData(development) {
      let self = this;

      if (development._id) {
        self.developmentID = development._id;
      }

      if (development.media) {
        if (development.media.featured_image) {
          self.previewMediaFeature = development.media.featured_image.src;
        }
      }
      if (development.media.structure) {
        if (development.media.structure.src) {
          self.previewMediaStructure = development.media.structure.src;
        }
      }
      if (development.development_status) {
        this.date_delivery = development.development_status.delivery_date;
        this.actualStatus = this.getStatus(
          development.development_status.actual_status
        );
        this.start_date = development.development_status.start_date;
      }
      if (development.contact_data) {
        if (development.contact_data.social_networks) {
          this.facebookEstate =
            development.contact_data.social_networks.facebook;
          this.instagramEstate =
            development.contact_data.social_networks.instagram;
        }
      }

      if (development.address) {
        if (development.address.city) {
          self.city_address = development.address.city;
        }

        if (development.address.state) {
          self.state_address = development.address.state;
        }

        if (development.address.full_address) {
          self.fullAddress = development.address.full_address;
        }
      }

      if (development.contact_data) {
        if (development.contact_data.phone) {
          self.phoneEstate = development.contact_data.phone;
        }
        if (development.contact_data.email) {
          self.emailEstate = development.contact_data.email;
        }
        if (development.contact_data.web) {
          self.linkWeb = development.contact_data.web;
        }
        if (development.contact_data.brochure) {
          self.brochureLink = development.contact_data.brochure;
        }
        if (development.contact_data.drive_folder) {
          self.driveLink = development.contact_data.drive_folder;
        }
      }
    },

    updateAll() {
      let self = this;
      self.successMessage = "Información Actualizada";
      self.errorMessage = "Error al actualizar la información";
      const data = {
        media: {
          featured_image: {
            src: self.previewMediaFeature
          },
          structure: {
            src: self.previewMediaStructure
          }
        },
        address: {
          country: "",
          state: self.state_address,
          city: self.city_address,
          zone: "",
          full_address: self.fullAddress
        },

        contact_data: {
          email: this.emailEstate,
          phone: this.phoneEstate,
          web: this.linkWeb,
          drive_folder: this.driveLink,
          brochure: this.brochureLink,
          social_networks: {
            facebook: this.facebookEstate,
            instagram: this.instagramEstate
          }
        },
        development_status: {
          actual_status: this.setStatus(this.actualStatus),
          delivery_date: this.date_delivery,
          start_date: this.start_date
        }
      };

      this.updateInformation(
        data,
        this.developmentID,
        "Actualizando información"
      );
    },

    updateImage() {
      let self = this;
      self.successMessage = "Media Actualizado";
      self.errorMessage = "Error al actualizar la Media";
      const uploadImage = {
        media: {
          featured_image: {
            src: this.previewMediaFeature
          },
          structure: {
            src: self.previewMediaStructure
          }
        }
      };
      this.updateInformation(
        uploadImage,
        this.developmentID,
        "Actualizando imagen"
      );
    },
    updataAddress() {
      let self = this;
      self.successMessage = "Dirección Actualizado";
      self.errorMessage = "Error al actualizar la Dirección";
      const updateAddressInfo = {
        address: {
          country: "",
          state: self.state_address,
          city: self.city_address,
          zone: "",
          full_address: self.fullAddress
        }
      };

      this.updateInformation(
        updateAddressInfo,
        this.developmentID,
        "Actualizando dirección"
      );
    },

    updateContactData() {
      let self = this;
      self.successMessage = "Datos de contacto Actualizado";
      self.errorMessage = "Error al actualizar la Datos de contacto";
      const updateCData = {
        contact_data: {
          email: this.emailEstate,
          phone: this.phoneEstate,
          web: this.linkWeb
        }
      };
      this.updateInformation(
        updateCData,
        this.developmentID,
        "Actualizando datos de contacto"
      );
    },
    updateDrive() {
      let self = this;
      self.successMessage = "Datos de drive Actualizado";
      self.errorMessage = "Error al actualizar la Datos de drive";
      const updateDrivedata = {
        contact_data: {
          drive_folder: this.driveLink,
          brochure: this.brochureLink
        }
      };
      this.updateInformation(
        updateDrivedata,
        this.developmentID,
        "Actualizando datos de drive"
      );
    },
    updateSocialMedia() {
      let self = this;
      self.successMessage = "Datos de redes sociales Actualizado";
      self.errorMessage = "Error al actualizar la Datos de redes sociales";
      const updateSM = {
        contact_data: {
          social_networks: {
            facebook: this.facebookEstate,
            instagram: this.instagramEstate
          }
        }
      };
      this.updateInformation(
        updateSM,
        this.developmentID,
        "Actualizando datos de redes sociales"
      );
    },
    updateStatusDevelopment() {
      let self = this;
      let tempStaus = "";
      self.successMessage = "Estatus Actualizado";
      self.errorMessage = "Error al actualizar la Estatus";

      if (this.actualStatus === "Inversión") {
        tempStaus = "investment";
      } else if (this.actualStatus === "En Obra") {
        tempStaus = "construction";
      } else if (this.actualStatus === "Terminado") {
        tempStaus = "finished";
      } else if (this.actualStatus === "Indefinido") {
        tempStaus = "";
      } else {
        tempStaus = this.developmentInformation.development_status
          .actual_status;
      }
      const devStatus = {
        development_status: {
          actual_status: tempStaus
            ? tempStaus
            : this.developmentInformation.development_status.actual_status,
          delivery_date: this.date
            ? this.date
            : this.developmentInformation.development_status.delivery_date,
          start_date: "2019-08-09"
        }
      };
      this.updateInformation(
        devStatus,
        this.developmentID,
        "Actualizando imagen"
      );
    },

    handleSubmit() {
      if (!this.activeRequest) {
        if (this.$refs.form.validate()) {
          this.updateImage();
        }
      }
    },
    handleSubmitStatus() {
      if (!this.activeRequest) {
        if (this.$refs.form.validate()) {
          this.updateStatusDevelopment();
        }
      }
    },
    handleSubmitAddress() {
      if (!this.activeRequest) {
        if (this.$refs.form.validate()) {
          this.updataAddress();
        }
      }
    },
    handleContactData() {
      if (!this.activeRequest) {
        if (this.$refs.form.validate()) {
          this.updateContactData();
        }
      }
    },
    handleSocialMedia() {
      if (!this.activeRequest) {
        if (this.$refs.form.validate()) {
          this.updateSocialMedia();
        }
      }
    },
    handleDrive() {
      if (!this.activeRequest) {
        if (this.$refs.form.validate()) {
          this.updateDrive();
        }
      }
    },
    updateInformation(dataUpdate, developmentID, message) {
      let self = this;
      self.loading = true;
      self.activeRequest = true;
      this.$snotify.async(
        message,
        "Procesando ...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              UpdateRealEstateDevelopment(dataUpdate, developmentID)
                /* eslint-disable no-unused-vars */
                .then(data => {
                  // self.real_estate_pricing_rules.push(rulePrice.PricingRules);
                  resolve({
                    title: `${self.successMessage}`,
                    body: `Proceso ejecutado correctamente`,
                    config: {
                      closeOnClick: true,
                      titleMaxLength: 30,
                      timeout: 4000
                    }
                  });
                })
                .catch(err => {
                  reject({
                    title: `${self.errorMessage}!!!`,
                    body: `Ocurrio un problema en el proceso. ${err}`,
                    config: {
                      closeOnClick: true,
                      timeout: 4000
                    }
                  });
                })
                .finally(() => {
                  self.activeRequest = false;
                  self.loading = false;
                  // self.clearForm();
                });
            }, 500);
          })
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
