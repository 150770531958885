import { word } from "./multi-language";
let language = "spanish";

export function superFormat(data, lang) {
  //getDataOffice(data);
  if (lang) language = lang;
  return getDataOffice(data);
}
function getDataOffice(data) {
  let one = {
    level: [], //Nivel
    internal: 0, //Metros cuadrados internos
    terrace: 0, //Metros Cuadrados de terraza
    totalOffice: 0, //Metros cuadrados totales de oficina
    warehouse: 0, //Metros cuadrados de bodegas
    totalMeters: 0, //Metros totales
    offices: [], //Oficinas
    numberParkingSpaces: 0, //Cantidad de cajones de estacionamiento
    levelParkingLots: [], //Nivel de piso de estacionamientos
    warehouseLevel: [], //nivel de las bodegas
    numberWarehouses: 0 //Cantidad de Bodegas
  };
  data.forEach(e => {
    //Nivel
    if (e.floor && e.floor.code) one.level.push(e.floor.code);
    //Metros cuadrados internos
    one.internal += e.building.construction
      ? transformNumber(e.building.construction)
      : 0;
    //Metros Cuadrados de terraza
    one.terrace += e.building.terrace ? transformNumber(e.building.terrace) : 0;
    //Metros cuadrados totales de oficina
    one.totalOffice = one.internal + one.terrace;
    //Pasar las amenidades para calcular: metros, cantidad, niveles de bodegas y estacionamientos
    const [twarehouse, tparking] = getAmenityData(e.amenities);
    //Metros cuadrados de bodegas
    one.warehouse += twarehouse.meters;
    //Metros totales
    one.totalMeters = one.totalOffice + one.warehouse;
    //Oficinas
    if (e.code) one.offices.push(e.code);
    //Cajones de estacionamiento - cantidad
    one.numberParkingSpaces += tparking.count;
    //Nivel de piso de estacionamientos
    one.levelParkingLots = one.levelParkingLots.concat(tparking.floor);
    //Nivel de las bodegas
    one.warehouseLevel = one.warehouseLevel.concat(twarehouse.floor);
    //Cantidad de Bodegas
    one.numberWarehouses += twarehouse.count;
  });
  one.totalMetersUnrounded = one.totalMeters;
  //Datos que se tienen que eliminar los repetidos y juntar en un array
  one.level = removeDuplicatesConcatenate(one.level);
  one.levelParkingLots = removeDuplicatesConcatenate(one.levelParkingLots);
  one.offices = removeDuplicatesConcatenate(one.offices);
  one.warehouseLevel = removeDuplicatesConcatenate(one.warehouseLevel);
  //Datos que se tiene que formatear a dos puntos decimales
  //.toFixed(2)
  one.internal = one.internal.toFixed(2);
  one.terrace = one.terrace.toFixed(2);
  one.totalMeters = one.totalMeters.toFixed(2);
  one.totalOffice = one.totalOffice.toFixed(2);
  one.warehouse = one.warehouse.toFixed(2);

  return officeDataStructure(one);
}
function transformNumber(number) {
  if (typeof number == "number") return number;
  if (number == "") return 0;
  return parseFloat(number);
}
function removeDuplicatesConcatenate(list) {
  const tp = [...new Set(list)];
  return tp.join(", ");
}

function getAmenityData(amenities) {
  let warehouse = {
    count: 0,
    meters: 0,
    floor: []
  };
  let parking = {
    count: 0,
    meters: 0,
    floor: []
  };
  if (!amenities) return [warehouse, parking];
  amenities.forEach(a => {
    if (a.name == "Bodega") {
      //Calcular la cantidad..
      warehouse.count++;
      //Calcular los metros cuadrados..
      warehouse.meters += a.construction.total
        ? transformNumber(a.construction.total)
        : 0;
      //Agregar los niveles
      if (a.floor && a.floor.code) warehouse.floor.push(a.floor.code);
    }
    if (a.name == "Cajon") {
      //Calcular la cantidad..
      parking.count++;
      //Calcular los metros cuadrados..
      parking.meters += a.construction.total
        ? transformNumber(a.construction.total)
        : 0;
      //Agregar los niveles
      if (a.floor && a.floor.code) parking.floor.push(a.floor.code);
    }
  });
  return [warehouse, parking];
}

function officeDataStructure(data) {
  const dataTable = [
    //Izquierdo
    {
      name: `${word(68, language)}`,
      result: data.level
    },
    {
      name: `${word(69, language)}`,
      result: data.internal
    },
    {
      name: `${word(70, language)}`,
      result: data.terrace
    },

    {
      name: `${word(71, language)}`,
      result: data.totalOffice
    },
    {
      name: `${word(72, language)}`,
      result: data.warehouse
    },
    {
      name: `${word(73, language)}`,
      result: data.totalMeters
    },
    //Derecho
    {
      name: `${word(74, language)}`,
      result: data.offices
    },
    {
      name: `${word(75, language)}`,
      result: data.numberParkingSpaces
    },
    // {
    //   name: `${word(76, language)}`,
    //   result: data.levelParkingLots
    // },
    // {
    //   name: `${word(77, language)}`,
    //   result: data.warehouseLevel
    // },
    {
      name: `${word(78, language)}`,
      result: data.numberWarehouses
    }
  ];
  return {
    dataTable: dataTable,
    totalMeters: data.totalMetersUnrounded
  };
}

export function getTotalMeters(data) {
  let one = {
    level: [], //Nivel
    internal: 0, //Metros cuadrados internos
    terrace: 0, //Metros Cuadrados de terraza
    totalOffice: 0, //Metros cuadrados totales de oficina
    warehouse: 0, //Metros cuadrados de bodegas
    totalMeters: 0, //Metros totales
    offices: [], //Oficinas
    numberParkingSpaces: 0, //Cantidad de cajones de estacionamiento
    levelParkingLots: [], //Nivel de piso de estacionamientos
    warehouseLevel: [], //nivel de las bodegas
    numberWarehouses: 0 //Cantidad de Bodegas
  };
  data.forEach(e => {
    //Nivel
    if (e.floor && e.floor.code) one.level.push(e.floor.code);
    //Metros cuadrados internos
    one.internal += e.building.construction
      ? transformNumber(e.building.construction)
      : 0;
    //Metros Cuadrados de terraza
    one.terrace += e.building.terrace ? transformNumber(e.building.terrace) : 0;
    //Metros cuadrados totales de oficina
    one.totalOffice = one.internal + one.terrace;
    //Pasar las amenidades para calcular: metros, cantidad, niveles de bodegas y estacionamientos
    const [twarehouse, tparking] = getAmenityData(e.amenities);
    //Metros cuadrados de bodegas
    one.warehouse += twarehouse.meters;
    //Metros totales
    one.totalMeters = one.totalOffice + one.warehouse;
    //Oficinas
    if (e.code) one.offices.push(e.code);
    //Cajones de estacionamiento - cantidad
    one.numberParkingSpaces += tparking.count;
    //Nivel de piso de estacionamientos
    one.levelParkingLots = one.levelParkingLots.concat(tparking.floor);
    //Nivel de las bodegas
    one.warehouseLevel = one.warehouseLevel.concat(twarehouse.floor);
    //Cantidad de Bodegas
    one.numberWarehouses += twarehouse.count;
  });

  //Datos que se tienen que eliminar los repetidos y juntar en un array
  one.level = removeDuplicatesConcatenate(one.level);
  one.levelParkingLots = removeDuplicatesConcatenate(one.levelParkingLots);
  one.offices = removeDuplicatesConcatenate(one.offices);
  one.warehouseLevel = removeDuplicatesConcatenate(one.warehouseLevel);
  //Datos que se tiene que formatear a dos puntos decimales
  //.toFixed(2)
  one.internal = one.internal.toFixed(2);
  one.terrace = one.terrace.toFixed(2);
  one.totalMeters = one.totalMeters.toFixed(2);
  one.totalOffice = one.totalOffice.toFixed(2);
  one.warehouse = one.warehouse.toFixed(2);
  return one.totalMeters;
}
