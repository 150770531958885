// 0 -> Ingles
// 1 -> Español
const languages = {
  english: 0,
  spanish: 1,
};

export const word = (what, language) => {
  return words[what][languages[language]] || "not found";
};
// Palabras multi-idioma
const words = {
  1: ["Proposal", "Propuesta"],
  2: ["Real Estate Agent", "Profesional Inmobiliario"],
  3: ["Broker Phone", "Teléfono Asesor"],
  4: ["UNIT", "UNIDAD"],
  5: ["SURFACE", "SUPERFICIE"],
  6: ["ECONOMIC PROPOSAL", "PROPUESTA ECONÓMICA"],
  7: ["UNITS", "UNIDAD"],
  8: ["PAYMENT SCHEMA", "ESQUEMA DE PAGO"],
  9: ["Level", "Nivel"],
  10: ["Type", "Tipo"],
  11: ["Rooms", "Recámaras"],
  12: ["Bathrooms", "Baños"],
  13: ["Inside Area", "Interior"],
  14: ["Terrace", "Terraza"],
  15: ["Total M2", "M2 Total"],
  16: ["List Price", "Precio de lista"],
  17: ["PAYMENT SCHEMA", "ESQUEMA DE PAGOS"],
  18: ["Down payment", "Enganche"],
  19: ["Financing", "Financiamiento"],
  20: ["Monthly payments", "Pagos mensuales"],
  21: ["Lead name", "Nombre del Lead"],
  22: ["Properties", "Propiedades"],
  23: ["Choose currency", "Elegir moneda"],
  24: ["Payment method", "Forma de pago"],
  25: ["Payment scheme", "Esquema de pagos"],
  26: ["Select broker", "Elegir broker"],
  27: ["Lead data", "Datos del Lead"],
  28: ["Next", "Continuar"],
  30: ["Pricing rules", "Reglas de precios"],
  31: ["List price without VAT", "Precio inicial sin IVA"],
  32: ["Final price without VAT", "Precio final sin IVA"],
  33: ["Final price with VAT", "Precio final con IVA"],
  34: ["Settlement", "Liquidación"],
  35: ["Payment scheme", "Esquema de pagos"],
  36: ["Final price", "Precio final"],
  37: ["Lead", "Lead"],
  38: ["Download Quote", "Descargar Cotización"],
  39: ["Broker", "Asesor"],
  40: ["Select broker", "Selecciona un asesor"],
  //Dentro de los componentes...
  41: ["List price", "Precio inicial"],
  42: ["Final price", "Precio final"],
  43: ["Pricing rules", "Reglas de precios"],
  44: ["Reservation fee", "Apartado"],
  45: ["Monthly installments", "Mensualidades"],
  46: ["VAT", "VAT"],
  47: ["Total discount", "Descuento total"],
  48: ["PURCHASE SCHEME", "ESQUEMA DE COMPRA"],
  49: ["SALE", "VENTA"],
  50: ["PRE-SALE", "PREVENTA"],
  51: ["PRICE OF", "PRECIO DE"],
  52: ["OFFER OF", "OFERTA DE"],
  53: ["Price without VAT", "Precio sin IVA"],
  54: ["Price with VAT", "Precio con IVA"],
  55: ["Price m² without VAT", "Precio m² sin IVA"],
  56: [
    "Add extra discount (Maximum cumulative",
    "Agregar descuento extra (Maximo acumulable",
  ],
  57: ["Delete discount", "Borrar descuento"],
  58: ["LOCATION", "UBICACIÓN"],
  59: ["Select pricing rule", "Selecciona una regla de precio"],
  60: ["Language", "Idioma"],
  61: ["Pricing rule", "Regla de precio"],
  62: ["PRE-SALE PRICE", "PRECIO DE PREVENTA"],
  63: ["SALE PRICE", "PRECIO DE VENTA"],
  64: ["PRE-SALE OFFER", "OFERTA DE PREVENTA"],
  65: ["SALE OFFER", "OFERTA DE VENTA"],
  66: ["OFFICE DATA", "DATOS DE LA OFICINA"],
  67: ["OFFICE LOCATION", "UBICACIÓN DE LA OFICINA"],
  //Datos de la oficina..
  68: ["LEVEL", "NIVEL"],
  69: ["INTERIOR SQUARE METERS", "METROS CUADRADOS INTERIORES"],
  70: ["SQUARE METERS OF TERRACE", "METROS CUADRADOS DE TERRAZA"],
  71: ["TOTAL SQUARE METERS OFFICE", "METROS CUADRADOS TOTALES OFICINA"],
  72: ["WAREHOUSE OF SQUARE METERS", "METROS CUADRADOS BODEGA"],
  73: ["TOTAL SQUARE METERS", "METROS CUADRADOS TOTALES"],
  74: ["OFFICE", "OFICINA"],
  75: ["PARKING BOXES", "CAJONES DE ESTACIONAMIENTO"],
  76: ["PARKING FLOOR LEVEL", "NIVEL DE PISO DEL ESTACIONAMIENTO"],
  77: ["WAREHOUSE LEVEL", "NIVEL DE PISO DE LAS BODEGAS"],
  78: ["WAREHOUSES", "BODEGAS"],
  79: ["Select properties", "Seleccionar propiedades"],
  80: ["Selected Properties", "Propiedades seleccionadas"],
  81: ["UNIT DETAIL", "DETALLE DE UNIDAD"],
  82: ["Parking", "Cajones"],
  83: ["Laundry room", "Cuarto de lavado"],
  84: ["Warehouse", "Bodega"],
  85: ["Walking Closet", "Walking Closet"],
  86: ["SURFACE TOTAL", "SUPERFICIE TOTAL"],
  87: ["Surface", "Superficie"],
  88: ["AMENITIES DETAIL", "DETALLE DE AMENIDADES"],
  89: ["Laundry closet", "Closet de lavado"],
  90: [
    "Validity of the quotation:\nUp to 7 days from the date of issue.",
    "Vigencia de la cotización:\nHasta 7 días desde la fecha de emisión",
  ],
  91: ["Amounts in Mexican pesos", "Importes en pesos mexicanos"],
  92: ["Issue date:", "Fecha de emisión:"],
  93: ["* Approximate measurements", "* Medidas aproximadas"],
  94: ["Parcialities", "Parcialidades"],
  95: ["Monthly payment of:", "Pago Mensual De:"],
};
