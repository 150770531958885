import { jsPDF } from "jspdf";
import "jspdf-autotable";
import LatoBoldnormal from "@/pdf/fonts/LatoBoldnormal";
import LatoRegularnormal from "@/pdf/fonts/LatoRegularnormal";
import LatoLightnormal from "@/pdf/fonts/LatoLightnormal";

let logo = [];
let galeria = [];
let featuredImage = [];
let contactMedia = [];
let logoFooter = "sfjj";
//Divisa que se maneja internamente
let currency;

let doc;
let color = {
  container_one: "#042956",
  container_two: "#DBE0E0",
  container_separator: "#636565",
  page_background: "#283042",
  text_color: "#ffffff",
  title_color: "#ffffff",
  title2_color: "#00000",
  text3_color: "#FFEB3BFF"
};

LatoRegularnormal();
LatoBoldnormal();
LatoLightnormal();

function start() {
  doc = jsPDF("l", "mm", [677.3, 381]);
  doc.setProperties({
    title: "Automatic quote",
    subject: "Quote",
    author: "Ing. Cesar A. Moheno (augustmoheno@gmail.com)",
    keywords: "generated, javascript, jspdf, automatic, quote",
    creator: "Created with Capital28"
  });
  return doc;
}
function end(realEstateGroupLogo) {
  doc.addPage();
  bg("white");
  const [imgWidthEnd, imgHeightEnd] = scaleImageSize(
    realEstateGroupLogo ? realEstateGroupLogo : logo[0],
    200
  );

  const { pageWidth, pageHeight } = positionSacale();
  const centerWidth = (pageWidth - imgWidthEnd) / 2;
  const centerHeight = (pageHeight - imgHeightEnd) / 2;
  doc.addImage(
    realEstateGroupLogo
      ? decodeURIComponent(realEstateGroupLogo)
      : decodeURIComponent(logo[0]),
    "PNG",
    centerWidth,
    centerHeight,
    imgWidthEnd,
    imgHeightEnd
  );
  contactMedia = [];
  return doc;
}
function text(tamaño, texto, x, y, font, c) {
  switch (font) {
    case "bold":
      doc.setFont("Lato-Bold", "normal");
      break;
    case "light":
      doc.setFont("Lato-Light", "normal");
      break;

    default:
      doc.setFont("Lato-Regular", "normal");
      break;
  }
  if (!c) {
    doc.setTextColor("#fffff");
  } else {
    doc.setTextColor(c);
  }
  doc.setFontSize(tamaño);
  doc.text(texto, x * 6.773, y * 3.81);
}

function graphicsFront() {
  doc.setDrawColor(0);
  doc.setFillColor(color.container_two);
  doc.rect(0, 0, 677.3, 190.5, "F");

  doc.setDrawColor(0);
  doc.setFillColor(color.container_one);
  doc.rect(0, 190.5, 677.3, 190.5, "F");

  doc.setDrawColor(color.title_color);
  doc.setLineWidth(0.7);
  doc.line(20 * 6.773, 43, 250, 43);

  let [imgWidthHead, imgHeightHead] = ([
    imgWidthHead,
    imgHeightHead
  ] = scaleImageSize(logo[0], 90));
  let scalaSize = 90;
  while (imgHeightHead > 40) {
    [imgWidthHead, imgHeightHead] = scaleImageSize(logo[0], scalaSize);
    scalaSize = scalaSize - 5;
  }

  let scale = imgWidthHead / imgHeightHead;
  if (imgHeightHead >= imgWidthHead) {
    doc.addImage(decodeURIComponent(logo[0]), "PNG", 53, 15, 60 * scale, 60);
  } else {
    if (imgWidthHead + 35 > 120) {
      scale = imgHeightHead / imgWidthHead;

      doc.addImage(
        decodeURIComponent(logo[0]),
        "PNG",
        20,
        30,
        100,
        100 * scale
      );
    } else {
      doc.addImage(decodeURIComponent(logo[0]), "PNG", 40, 20, 50 * scale, 50);
    }
  }

  const { pageHeight, centerWidth } = positionSacale();
  let [imgFeatureWidt, imgFeatureHeight] = scaleImageSize(
    featuredImage[0],
    400,
    400,
    true
  );
  if (imgFeatureHeight > 240) {
    const scala = imgFeatureWidt / imgFeatureHeight;
    imgFeatureHeight = 240;
    imgFeatureWidt = 240 * scala;
  }
  const centerHeightFeature = 20 + (pageHeight - imgFeatureHeight) / 2;
  const centerWidthFeature = 60 + (centerWidth - imgFeatureWidt) / 2;
  doc.addImage(
    decodeURIComponent(featuredImage[0]),
    "JPG",
    centerWidthFeature,
    centerHeightFeature,
    imgFeatureWidt,
    imgFeatureHeight
  );
}

function agent(name, x, y, lenguage, c) {
  c == null ? doc.setTextColor(40, 46, 66) : doc.setTextColor(c);
  if (contactMedia[0].includes("cloudinary")) {
    let tempColor = color.container_two.slice(1);
    let imgSplit = contactMedia[0].split("upload");
    let newImage =
      imgSplit[0] +
      `upload/w_1000,c_fill,ar_1:1,g_auto,r_max,b_rgb:${tempColor.toLocaleLowerCase()}` +
      imgSplit[1].toString();
    doc.addImage(encodeURI(newImage), "PNG", x, y, 60, 0);
  } else {
    doc.addImage(decodeURIComponent(contactMedia[0]), "PNG", x, y, 60, 0);
  }
  doc.setFont("Lato-Bold", "normal");
  doc.setFontSize(30);
  doc.setTextColor(color.text3_color);

  if (name.length < 22) {
    doc.text(name, x + 70, y + 23);
  } else {
    const arr = name.split(" ");

    let count = -1;
    let firstLine = "";
    let secondLine = "";
    const limit = arr.length > 3 ? 17 : 22;
    for (let i = 0; i < arr.length; i++) {
      count = count + arr[i].length + 1;
      if (count > limit) {
        secondLine = secondLine + arr[i] + " ";
      } else {
        firstLine = firstLine + arr[i] + " ";
      }
    }
    doc.text(firstLine, x + 70, y + 23);
    doc.text(secondLine, x + 70, y + 36);

    y += 14;
  }
  doc.setFont("Lato-Regular", "normal");
  doc.setFontSize(30);
  doc.text(
    lenguage == "Español" ? "Profesional Inmobiliario" : "Real Estate Agent",
    x + 70,
    y + 36
  );
  return doc;
}

function addProfileImage(media) {
  if (media) {
    if (media.featured_image) {
      if (media.featured_image.src) {
        contactMedia.push(media.featured_image.src);
      } else if (media.featured_image.src_avatar) {
        contactMedia.push(media.featured_image.src_avatar);
      } else {
        contactMedia.push(
          "https://res.cloudinary.com/capital28-investments/image/upload/v1594414146/Capital%2028/brokers/testbroker.jpg"
        );
      }
    } else {
      contactMedia.push(
        "https://res.cloudinary.com/capital28-investments/image/upload/v1594414146/Capital%2028/brokers/testbroker.jpg"
      );
    }
  } else {
    contactMedia.push(
      "https://res.cloudinary.com/capital28-investments/image/upload/v1594414146/Capital%2028/brokers/testbroker.jpg"
    );
  }
}

function addLogoDevelopment(media) {
  if (media) {
    //Se agrega el logo del footer
    if (media.logo_footer) {
      if (media.logo_footer.src) {
        logoFooter = media.logo_footer.src;
      } else {
        logoFooter = null;
      }
    } else {
      logoFooter = null;
    }
    if (media.logo) {
      if (media.logo.src) {
        logo[0] = media.logo.src;
      } else {
        logo[0] =
          "https://res.cloudinary.com/capital28-investments/image/upload/v1564761056/Capital%2028/logo-capital-28.png";
      }
    } else {
      logo[0] =
        "https://res.cloudinary.com/capital28-investments/image/upload/v1564761056/Capital%2028/logo-capital-28.png";
    }
  } else {
    logo[0] =
      "https://res.cloudinary.com/capital28-investments/image/upload/v1564761056/Capital%2028/logo-capital-28.png";
  }
}

function addFeaturedImageDevelopment(media) {
  if (media) {
    if (media.featured_image) {
      if (media.featured_image.src) {
        featuredImage[0] = media.featured_image.src;
      } else {
        featuredImage[0] =
          "https://res.cloudinary.com/capital28-investments/image/upload/v1628213119/Capital%2028/Inmobiliarias/imagem-not-found.jpg";
      }
    } else {
      featuredImage[0] =
        "https://res.cloudinary.com/capital28-investments/image/upload/v1628213119/Capital%2028/Inmobiliarias/imagem-not-found.jpg";
    }
  } else {
    featuredImage[0] =
      "https://res.cloudinary.com/capital28-investments/image/upload/v1628213119/Capital%2028/Inmobiliarias/imagem-not-found.jpg";
  }
}

function addGalleryDevelopment(media) {
  if (media) {
    if (media.gallery && media.gallery.length > 0) {
      media.gallery.forEach(galleryMedia => {
        galeria.push(galleryMedia.src);
      });
    } else {
      galeria.push(
        "https://res.cloudinary.com/capital28-investments/image/upload/v1628213119/Capital%2028/Inmobiliarias/imagem-not-found.jpg"
      );
    }
  } else {
    galeria.push(
      "https://res.cloudinary.com/capital28-investments/image/upload/v1628213119/Capital%2028/Inmobiliarias/imagem-not-found.jpg"
    );
  }
}

function getColorPDF(style) {
  if (style) {
    if (style.container_one) {
      color.container_one = style.container_one;
    }
    if (style.container_two) {
      color.container_two = style.container_two;
    }
    if (style.container_separator) {
      color.container_separator = style.container_separator;
    }
    if (style.page_background) {
      color.page_background = style.page_background;
    }
    if (style.title_color) {
      color.title_color = style.title_color;
    }
    if (style.text_color) {
      color.text_color = style.text_color;
    }
    if (style.title2_color) {
      color.title2_color = style.title2_color;
    }
    if (style.text3_color) {
      color.text3_color = style.text3_color;
    }
  }
}

function positionSacale() {
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  return {
    pageWidth,
    pageHeight,
    perimetro: (pageWidth + pageHeight) * 2,
    area: pageWidth * pageHeight,
    margin: 10,
    centerWidth: pageWidth / 2,
    centerHeight: pageHeight / 2
  };
}

function scaleImageSize(image, width, height, scale) {
  const imgProps = doc.getImageProperties(image);
  const { centerHeight, margin } = positionSacale();
  if (scale === true) {
    if (imgProps.height > centerHeight && imgProps.width < imgProps.height) {
      const imageWidth = (imgProps.width * height) / imgProps.height;
      return [imageWidth - margin, height - margin];
    } else {
      const imageHeight = (imgProps.height * width) / imgProps.width;
      return [width - margin, imageHeight - margin];
    }
  }
  if (width) {
    const imageHeight = (imgProps.height * width) / imgProps.width;
    return [width, imageHeight];
  }
  if (height) {
    const imageWidth = (imgProps.width * height) / imgProps.height;
    return [imageWidth, height];
  }
}

function photoShow() {
  doc.addPage();
  bg("dark");
  const { margin } = positionSacale();
  const [galeriaWidth, galeriaHeight] = scaleImageSize(
    galeria[0],
    580,
    300,
    true
  );
  doc.addImage(
    decodeURIComponent(galeria[0]),
    "JPG",
    50.0,
    margin,
    galeriaWidth,
    galeriaHeight
  );
  botttomLogo(logo[0]);
}
function bg(c) {
  doc.setDrawColor(0);
  c == "white"
    ? doc.setFillColor(color.container_two)
    : doc.setFillColor(color.container_one);
  doc.rect(0, 0, 677.3, 381, "F");
}

function blueprints(dep, asesor, lenguage) {
  let titulo;

  for (let a of dep) {
    if (a.media) {
      if (a.media.featured_image) {
        if (a.media.featured_image.src !== "") {
          doc.addPage();

          doc.setDrawColor(0);
          doc.setFillColor(color.container_two);
          doc.rect(0, 0, 677.3, 190.5, "F");

          doc.setDrawColor(0);
          doc.setFillColor(color.container_one);
          doc.rect(0, 190.5, 677.3, 190.5, "F");

          doc.setDrawColor(0);
          doc.setFillColor(255, 255, 255);

          //add plane
          planeImg(decodeURIComponent(a.media.featured_image.src));

          botttomLogo(logo[0]);

          if (a.code) {
            titulo =
              lenguage == "Español"
                ? "UNIDAD " + a.code.toUpperCase()
                : "UNIT " + a.code.toUpperCase();
            text(40, titulo, 10, 10, "bold", color.title_color);
          }

          rightSideSurface(a, asesor, lenguage);
        }
      }
    }
    //Aqui va el codigo para pintar las amenidades en los blueprints...
    if (a.amenities) {
      if (a.amenities.length > 0) {
        if (a.amenities) {
          blueprintsAmenities(a.amenities, asesor, lenguage);
        }
      }
    }
  }
}

function blueprintsAmenities(dep, asesor, lenguage) {
  let title;

  for (let a of dep) {
    if (a.media) {
      if (a.media.featured_image) {
        if (a.media.featured_image.src !== "") {
          doc.addPage();

          doc.setDrawColor(0);
          doc.setFillColor(color.container_two);
          doc.rect(0, 0, 677.3, 190.5, "F");

          doc.setDrawColor(0);
          doc.setFillColor(color.container_one);
          doc.rect(0, 190.5, 677.3, 190.5, "F");

          doc.setDrawColor(0);
          doc.setFillColor(255, 255, 255);

          //add plane
          planeImg(decodeURIComponent(a.media.featured_image.src));

          botttomLogo(logo[0]);

          if (a.code) {
            title = a.name + " ";

            text(40, title.toUpperCase(), 10, 10, "bold", color.title_color);
            text(25, a.name + "", 70, 60, "bold", color.text_color);
            text(
              25,
              a.construction.total + " m²",
              70 + 10,
              60,
              "",
              color.text_color
            );
          }
        }
      }
    }
    agent(asesor, 470, 110, lenguage);
    text(
      35,
      lenguage == "Español" ? "SUPERFICIE" : "SURFACE",
      70,
      55,
      "bold",
      color.text_color
    );
  }
}

function EconomicProposal(
  dep,
  lenguage,
  datos,
  moneda,
  tipoCambio,
  ifchange,
  includeIva,
  IVA,
  hideCurrency
) {
  if (hideCurrency) {
    currency = " ";
  } else {
    currency = moneda;
  }
  doc.addPage();
  bg("dark");
  text(
    40,
    lenguage == "Español" ? "PROPUESTA ECONÓMICA" : "ECONOMIC PROPOSAL",
    10,
    10,
    "bold",
    color.text_color
  );
  botttomLogo(logo[0]);
  let body = [];

  for (let a of dep) {
    body.push(structureTable(a, moneda, tipoCambio, ifchange, IVA));
    //Aqui se hace un push de las amenidades que existen...
    //Le tengo que pasar el body ..pushearle los datos...
    if (a.amenities) {
      unitsAmenities(a.amenities, body, a.building, dep[0].type);
    }
  }
  doc.autoTable({
    margin: { top: 70 },
    body: [
      [
        {
          content: lenguage == "Español" ? "UNIDADES" : "UNITS",
          styles: {
            halign: "center",
            fontSize: 30,
            font: "Lato-Bold",
            textColor: color.title_color,
            fillColor: color.container_two
          }
        }
      ]
    ]
  });
  let textPrice = "";
  if (lenguage == "Español") {
    if (includeIva === "off") {
      textPrice = "Precio de lista";
    } else textPrice = "Precio de lista con IVA";
  } else {
    if (includeIva === "off") {
      textPrice = "List Price";
    } else textPrice = "List Price with VAT";
  }

  //Función para poder ajustar el head
  let head;
  if (dep[0]) {
    head = configHead(dep[0].type, lenguage);
  }
  head.push(textPrice);
  const [headF, bodyF] = tableformat(head, body);

  //Amenidades en la tabla...
  doc.autoTable({
    head: [headF],

    body: bodyF,
    theme: "plain",
    styles: {
      halign: "center",
      cellPadding: 4,
      fontSize: 28,
      textColor: color.text_color,
      font: "Lato-Regular"
    }
  });

  esquemaPagos(datos, lenguage);
}

function unitsAmenities(ams, body, unit, type) {
  //se van hacer un push a la tabla de unidades para agregar las amenidades que existan.
  let total = 0;
  if (unit.total === 0) {
    total = unit.construction + unit.terrace;
  } else {
    total = unit.total;
  }

  let ob;
  let tempbody = [];
  for (let a of ams) {
    ob = structureTableAmenities(a, total, type);
    tempbody.push(ob.arr);
    //Aqui se hace un push de las amenidades que existen...
    //Le tengo que pasar el body ..pushearle los datos...
  }
  reduceBodyAmenities(tempbody, body);
  if (ams.length > 0) {
    if (ob.tn) {
      if (type.toUpperCase() === "STUDIO" || type.toUpperCase() === "ESTUDIO") {
        body.push(["", "", "", "", "", "", ob.tn + " m²", ""]);
      } else if (
        type.toUpperCase() === "OFICINA" ||
        type.toUpperCase() === "LOCAL COMERCIAL"
      ) {
        body.push(["", "", "", "", "", ob.tn + " m²", ""]);
      } else if (type.toUpperCase() === "LOTE") {
        body.push(["", "", "", ob.tn + " m²", ""]);
      } else {
        body.push(["", "", "", "", "", "", "", ob.tn + " m²", ""]);
      }
    }
  }
}
var esquemaPagos = (datos, lenguage) => {
  doc.autoTable({
    body: [
      [
        {
          content:
            lenguage == "Español" ? "ESQUEMA DE PAGOS" : "PAYMENT SCHEMA",
          styles: {
            halign: "center",
            fontSize: 30,
            font: "Lato-Bold",
            textColor: color.title_color,
            fillColor: color.container_two
          }
        }
      ]
    ]
  });

  //Currency debe estar de manera global
  for (let i = 0; i < datos.length; i++) {
    datos[i][2] = currency + " " + datos[i][2];
  }
  doc.autoTable({
    body: datos,
    theme: "plain",
    styles: {
      cellPadding: 4,
      font: "Lato-Regular",
      fontSize: 28,
      textColor: color.text_color
    },
    columnStyles: {
      0: { cellWidth: "wrap" },
      1: { cellWidth: "wrap" },
      2: { cellWidth: "wrap" }
    }
  });
};

var botttomLogo = img => {
  if (logoFooter) {
    img = logoFooter;
  }

  const { pageHeight, pageWidth, margin } = positionSacale();
  //Se le cambio el tamaño del logo inferior
  let [logoWidthFooter, logoHeightFooter] = scaleImageSize(img, 80);
  const scala = logoWidthFooter / logoHeightFooter;
  logoHeightFooter = 25;
  logoWidthFooter = logoHeightFooter * scala;
  const centerHeightFooter = pageHeight - logoHeightFooter - margin - 10;
  const centerWidthFooter = (pageWidth - logoWidthFooter) / 2;
  return doc.addImage(
    decodeURIComponent(img),
    "PNG",
    centerWidthFooter,
    centerHeightFooter,
    logoWidthFooter,
    logoHeightFooter
  );
};
function setParamsValue(condition, value, def) {
  if (condition) {
    return value;
  } else {
    return def;
  }
}

function planeImg(img) {
  const { pageHeight, centerWidth, margin } = positionSacale();

  let [imgPlanoWidth, imgPlanoHeight] = scaleImageSize(img, 330, 280, true);

  if (imgPlanoWidth === imgPlanoHeight) {
    [imgPlanoWidth, imgPlanoHeight] = scaleImageSize(img, 280, 280, true);
  } else if (imgPlanoWidth > imgPlanoHeight) {
    [imgPlanoWidth, imgPlanoHeight] = scaleImageSize(img, 250, 300, true);
  }
  const centerHeightPlano = (pageHeight - imgPlanoHeight) / 2;
  const centerWidthPlano = margin + (centerWidth - imgPlanoWidth) / 2;
  doc.addImage(
    decodeURIComponent(img),
    "PNG",
    centerWidthPlano,
    centerHeightPlano,
    imgPlanoWidth,
    imgPlanoHeight
  );
}

function tableformat(head, body) {
  let newHead = head;
  let newBody = body;
  return [newHead, newBody];
}

function ToFixed(x) {
  const w = x.toFixed(2);
  return new Intl.NumberFormat().format(w);
}
function structureTable(a, moneda, tipoCambio, ifchange, IVA) {
  const price =
    moneda == "MXN"
      ? currency +
        " $" +
        ToFixed(
          ifchange === false
            ? setParamsValue(a.pricing.price, a.pricing.price, 0) + IVA
            : setParamsValue(a.pricing.price, a.pricing.price, 0) * tipoCambio +
                IVA
        )
      : currency +
        " $" +
        ToFixed(
          ifchange === false
            ? setParamsValue(a.pricing.price, a.pricing.price, 0) + IVA
            : setParamsValue(a.pricing.price, a.pricing.price, 0) / tipoCambio +
                IVA
        );

  let data;
  if (a.type.toUpperCase() === "STUDIO" || a.type.toUpperCase() === "ESTUDIO") {
    data = [
      setParamsValue(a.code, a.code, ""),
      setParamsValue(a.floor.name, a.floor.name, ""),
      setParamsValue(a.building.type, a.building.type, "No definido"),
      setParamsValue(a.features.bathrooms, a.features.bathrooms, 0),
      setParamsValue(a.building.construction, a.building.construction, 0) +
        " m²",
      setParamsValue(a.building.terrace, a.building.terrace, 0) + " m²",
      setParamsValue(a.building.total, a.building.total, 0) + " m²"
    ];
  } else if (a.type.toUpperCase() === "LOTE") {
    data = [
      setParamsValue(a.code, a.code, ""),
      setParamsValue(a.floor.name, a.floor.name, ""),
      setParamsValue(a.building.type, a.building.type, "No definido"),
      setParamsValue(a.building.total, a.building.total, 0) + " m²"
    ];
  } else if (
    a.type.toUpperCase() === "OFICINA" ||
    a.type.toUpperCase() === "LOCAL COMERCIAL"
  ) {
    data = [
      setParamsValue(a.code, a.code, ""),
      setParamsValue(a.floor.name, a.floor.name, ""),
      setParamsValue(a.building.type, a.building.type, "No definido"),
      setParamsValue(a.building.construction, a.building.construction, 0) +
        " m²",
      setParamsValue(a.building.terrace, a.building.terrace, 0) + " m²",
      setParamsValue(a.building.total, a.building.total, 0) + " m²"
    ];
  } else {
    data = [
      setParamsValue(a.code, a.code, ""),
      setParamsValue(a.floor.name, a.floor.name, ""),
      setParamsValue(a.building.type, a.building.type, "No definido"),
      setParamsValue(a.features.rooms, a.features.rooms, 0),
      setParamsValue(a.features.bathrooms, a.features.bathrooms, 0),
      setParamsValue(a.building.construction, a.building.construction, 0) +
        " m²",
      setParamsValue(a.building.terrace, a.building.terrace, 0) + " m²",
      setParamsValue(a.building.total, a.building.total, 0) + " m²"
    ];
  }

  data.push(price);

  return data;
}

function rightSideSurface(a, asesor, lenguage) {
  let dx = 70;
  text(
    35,
    lenguage == "Español" ? "SUPERFICIE" : "SURFACE",
    dx,
    55,
    "bold",
    color.text_color
  );
  text(
    25,
    lenguage == "Español"
      ? "" + a.type.charAt(0).toUpperCase() + a.type.slice(1) + ""
      : a.type.charAt(0).toUpperCase() + a.type.slice(1),
    dx,
    60,
    "bold",
    color.text_color
  );

  text(
    25,
    setParamsValue(a.building.construction, a.building.construction, 0) + " m²",
    dx + 10,
    60,
    "",
    color.text_color
  );
  let positionYtemp = 64;

  if (a.building.terrace && a.building.terrace !== "") {
    text(
      25,
      lenguage == "Español" ? "Terraza " : "Terrace",
      dx,
      positionYtemp,
      "bold",
      color.text_color
    );
    text(
      25,
      setParamsValue(a.building.terrace, a.building.terrace, 0) + " m²",
      dx + 10,
      positionYtemp,
      "",
      color.text_color
    );
    positionYtemp = positionYtemp + 4;
  }

  text(25, "Total ", dx, positionYtemp, "bold", color.text_color);

  let tempTotal = setParamsValue(a.building.total, a.building.total, 0);

  if (tempTotal === 0) {
    tempTotal =
      tempTotal +
      setParamsValue(a.building.construction, a.building.construction, 0) +
      setParamsValue(a.building.terrace, a.building.terrace, 0);

    a.building.total = tempTotal;
  }

  text(25, tempTotal + " m²", dx + 10, positionYtemp, "", color.text_color);

  agent(asesor, 470, 110, lenguage);
}

function structureTableAmenities(a, total, type) {
  let arr;
  if (type.toUpperCase() === "STUDIO" || type.toUpperCase() === "ESTUDIO") {
    arr = [
      setParamsValue(a.code, a.code, ""),
      setParamsValue(a.floor.name, a.floor.name, ""),
      setParamsValue(a.name, a.name, ""),
      "",
      "",
      "",
      setParamsValue(a.construction.total, a.construction.total, "") + " m²",
      ""
    ];
  } else if (
    type.toUpperCase() === "OFICINA" ||
    type.toUpperCase() === "LOCAL COMERCIAL"
  ) {
    arr = [
      setParamsValue(a.code, a.code, ""),
      setParamsValue(a.floor.name, a.floor.name, ""),
      setParamsValue(a.name, a.name, ""),
      "",
      "",
      setParamsValue(a.construction.total, a.construction.total, "") + " m²",
      ""
    ];
  } else if (type.toUpperCase() === "LOTE") {
    arr = [
      setParamsValue(a.code, a.code, ""),
      setParamsValue(a.floor.name, a.floor.name, ""),
      setParamsValue(a.name, a.name, ""),
      setParamsValue(a.construction.total, a.construction.total, "") + " m²",
      ""
    ];
  } else {
    arr = [
      setParamsValue(a.code, a.code, ""),
      setParamsValue(a.floor.name, a.floor.name, ""),
      setParamsValue(a.name, a.name, ""),
      "",
      "",
      "",
      "",
      setParamsValue(a.construction.total, a.construction.total, "") + " m²",
      ""
    ];
  }

  const data = {
    arr,
    tn: total + a.construction.total
  };

  return data;
}

function configHead(type, lenguage) {
  if (type.toUpperCase() === "STUDIO" || type.toUpperCase() === "ESTUDIO") {
    return [
      lenguage == "Español" ? "Unidad" : "Unit",
      lenguage == "Español" ? "Nivel" : "Level",
      lenguage == "Español" ? "Tipo" : "Type",
      lenguage == "Español" ? "Baños" : "Bathroom",
      lenguage == "Español" ? "Interior" : "Inside Area",
      lenguage == "Español" ? "Terraza" : "Terrace",
      lenguage == "Español" ? "M2 Total" : "Total M2"
    ];
  } else if (
    type.toUpperCase() === "OFICINA" ||
    type.toUpperCase() === "LOCAL COMERCIAL"
  ) {
    return [
      lenguage == "Español" ? "Unidad" : "Unit",
      lenguage == "Español" ? "Nivel" : "Level",
      lenguage == "Español" ? "Tipo" : "Type",
      lenguage == "Español" ? "Interior" : "Inside Area",
      lenguage == "Español" ? "Terraza" : "Terrace",
      lenguage == "Español" ? "M2 Total" : "Total M2"
    ];
  } else if (type.toUpperCase() === "LOTE") {
    return [
      lenguage == "Español" ? "Unidad" : "Unit",
      lenguage == "Español" ? "Nivel" : "Level",
      lenguage == "Español" ? "Tipo" : "Type",
      lenguage == "Español" ? "M2 Total" : "Total M2"
    ];
  } else {
    return [
      lenguage == "Español" ? "Unidad" : "Unit",
      lenguage == "Español" ? "Nivel" : "Level",
      lenguage == "Español" ? "Tipo" : "Type",
      lenguage == "Español" ? "Recámaras" : "Rooms",
      lenguage == "Español" ? "Baños" : "Bathroom",
      lenguage == "Español" ? "Interior" : "Inside Area",
      lenguage == "Español" ? "Terraza" : "Terrace",
      lenguage == "Español" ? "M2 Total" : "Total M2"
    ];
  }
}

function reduceBodyAmenities(tempbody, body) {
  let leveltemp = [];
  let listOfCube = [];
  for (var i = 0; i < tempbody.length; i++) {
    if (tempbody[i][2].toUpperCase() == "CAJON") {
      listOfCube.push(tempbody[i]);
      leveltemp.push(tempbody[i][1]);
    } else {
      body.push(tempbody[i]);
    }
  }
  leveltemp = [...new Set(leveltemp)];
  let repeated = [];
  for (var q = 0; q < leveltemp.length; q++) {
    repeated = [];
    for (var x = 0; x < listOfCube.length; x++) {
      if (listOfCube[x][1] == leveltemp[q]) {
        repeated.push(listOfCube[x][0]);
      }
    }
    body.push([repeated.join(" , "), leveltemp[q], "Cajón"]);
  }
  repeated = [];
  leveltemp = [];
  listOfCube = [];
}

export default {
  start,
  end,
  text,
  graphicsFront,
  addProfileImage,
  addLogoDevelopment,
  addFeaturedImageDevelopment,
  addGalleryDevelopment,
  getColorPDF,
  agent,
  photoShow,
  blueprints,
  EconomicProposal,
  blueprintsAmenities,
  unitsAmenities
};
